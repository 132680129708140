import { connect } from 'react-redux';
import { settingsActions } from 'redux/settings';
import EmailNotificationRule from './EmailNotificationRule';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    emailTriggerLoading: state.settings.emailTriggerLoading,
    emailTriggerMeta: state.settings.emailTriggerMeta,
    emailToaster: state.settings.emailToaster,
    emailTriggerDetail: state.settings.emailTriggerDetail,
    emailTriggerDetailDeleted: state.settings.emailTriggerDetailDeleted,
    emailTriggerDetailSaved: state.settings.emailTriggerDetailSaved,
    emailTriggerCreated: state.settings.emailTriggerCreated,
    loading: state.settings.loading,
  };
}

const mapDispatchToProps = {
  getEmailTriggersMeta: settingsActions.Creators.getEmailTriggersMeta,
  activateEmailTrigger: settingsActions.Creators.activateEmailTrigger,
  deactivateEmailTrigger: settingsActions.Creators.deactivateEmailTrigger,
  resetEmailToaster: settingsActions.Creators.resetEmailToaster,
  deleteEmailTriggerById: settingsActions.Creators.deleteEmailTriggerById,
  testEmailTriggerById: settingsActions.Creators.testEmailTriggerById,
  getEmailTriggerById: settingsActions.Creators.getEmailTriggerById,
  putEmailTriggerById: settingsActions.Creators.putEmailTriggerById,
  postEmailTrigger: settingsActions.Creators.postEmailTrigger,
  resetEmailTriggerDetail: settingsActions.Creators.resetEmailTriggerDetail,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailNotificationRule);
