import { connect } from 'react-redux';
import { settingsActions } from 'redux/settings';
import ThankyouPageCustomization from './ThankyouPageCustomization';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    orderStatusConfigurations: state.settings.orderStatusConfigurations,
  };
}

const mapDispatchToProps = {
  getOrderStatusPageConfigurations:
    settingsActions.Creators.getOrderStatusPageConfigurations,
  activateFeature: settingsActions.Creators.activateFeature,
  deactivateFeature: settingsActions.Creators.deactivateFeature,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThankyouPageCustomization);
