import { connect } from 'react-redux';
import { settingsActions } from 'redux/settings';
import SettingsToaster from './SettingsToaster';

function mapStateToProps(state) {
  return {
    settingsToaster: state.settings.settingsToaster,
  };
}

const mapDispatchToProps = {
  resetSettingsToaster: settingsActions.Creators.resetSettingsToaster,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsToaster);
