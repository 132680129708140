import { connect } from 'react-redux';
// import { TrackingPageCreators } from 'redux/actions';
import ShipmentSection from './ShipmentSection';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    orderStatusMeta: state.settings.orderStatusMeta,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentSection);
