import _ from 'lodash';
import { FREE_TRACKINGS_FORMAT } from 'Constants';
import i18next from 'i18next';
const string = {
  parseQueryString: function (queryString) {
    const result = {};
    const start = queryString.substring(0, 1) === '?' ? 1 : 0;
    const components = queryString.substring(start).split('&');
    for (const component of components) {
      const [key, val] = component.split('=');
      result[key] = decodeURIComponent(val);
    }
    return result;
  },
  parseMoneyFormat: function (moneyFormat, amount) {
    return amount;
  },
  isNumeric: function (obj) {
    const realStringObj = obj && obj.toString();
    return (
      !_.isArray(obj) && realStringObj - parseFloat(realStringObj) + 1 >= 0
    );
  },
  formatAPIError: function (action) {
    return {
      title:
        action.error.display && action.error.display.title
          ? action.error.display.title
          : action.error.message,
      description:
        action.error.display && action.error.display.description
          ? action.error.display.description
          : action.error.message,
    };
  },
  getErrorText: function (action) {
    return action.error.display
      ? action.error.display.description || action.error.display.title
      : action.error.message;
  },
  getErrorObject: function (action) {
    return (
      action.error.display || {
        title: action.error.message,
        description: action.error.message,
      }
    );
  },
  validateUrl: function (value) {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value
    );
  },
  validateRegex: function (input) {
    const m = input.match(/(\/?)(.+)\1([a-z]*)/i);
    // Invalid flags
    if (m[3] && !/^(?!.*?(.).*?\1)[i]+$/.test(m[3])) {
      return RegExp(input);
    }

    // Create the regular expression
    return new RegExp(m[2], m[3]);
  },
  isRegexValid: function (value) {
    if (value.toString().indexOf('/') !== 0) {
      return false;
    } else if ('i/'.indexOf(value[value.toString().length - 1]) < 0) {
      return false;
    }
    try {
      string.validateRegex(value);
    } catch (error) {
      if (error.name === 'SyntaxError') {
        return false;
      } else {
        throw error;
      }
    }
    return true;
  },
  formatNumber: function (input, format) {
    if (!input) {
      return input;
    }
    const inputString = input.toString();
    const reverseStr = inputString.split('').reverse().join('');
    // Replace each "X" with the next digit
    let count = 0;
    const result = format.replace(/X/g, function () {
      return reverseStr.charAt(count++);
    });
    return result.trim().split('').reverse().join('');
  },
  formatAmount: function (input) {
    const format = FREE_TRACKINGS_FORMAT;
    if (!input) {
      return input;
    }
    const inputAsString = input.toString();
    let inputString;
    let splitAmount;
    if (inputAsString.indexOf('.') > 0) {
      splitAmount = inputAsString.split('.');
      inputString = splitAmount[0];
    } else {
      inputString = inputAsString;
    }

    const reverseStr = inputString.split('').reverse().join('');
    // Replace each "X" with the next digit
    let count = 0;
    const result = format.replace(/X/g, function () {
      return reverseStr.charAt(count++);
    });
    const formattedAmount = result
      .trim()
      .split('')
      .reverse()
      .join('')
      .split(' ')
      .join(',');
    if (inputAsString.indexOf('.') > 0) {
      return `${formattedAmount}.${splitAmount[1]}`;
    } else {
      return `${formattedAmount}`;
    }
  },
  getLanguage: function () {
    try {
      return (
        i18next.language ||
        (typeof window !== 'undefined' &&
          window.localStorage &&
          window.localStorage.i18nextLng) ||
        'en'
      );
    } catch (error) {
      return 'en';
    }
  },
  transformToCurrency: function (value) {
    return Number(value).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  },
};

export default string;
