import { connect } from 'react-redux';
import { settingsActions } from 'redux/settings';
import EmailPreviewModal from './EmailPreviewModal';

function mapStateToProps(state) {
  return {
    emailTriggerMeta: state.settings.emailTriggerMeta,
    emailTemplatePreview: state.settings.emailTemplatePreview,
  };
}

const mapDispatchToProps = {
  getEmailTemplatePreview: settingsActions.Creators.getEmailTemplatePreview,
  resetEmailTemplatePreview: settingsActions.Creators.resetEmailTemplatePreview,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailPreviewModal);
