import { storeApi } from '../../redux/store/storeApi';

export const settingsAPIs = storeApi.injectEndpoints({
  endpoints: (builder) => ({
    getSettings: builder.query({
      query: (params) => {
        const urlParams = new URLSearchParams({ ...params }).toString();
        return {
          url: `/features/add-ons?${urlParams}`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      providesTags: ['settings'],
    }),
    activateFeature: builder.mutation({
      query: (featureId) => {
        return {
          url: `/feature/${featureId}/activate`,
          method: 'PUT',
        };
      },
      invalidatesTags: ['settings'],
      transformResponse: (response = {}) => response?.data || response,
    }),
    deactivateFeature: builder.mutation({
      query: (featureId) => {
        return {
          url: `/feature/${featureId}/deactivate`,
          method: 'PUT',
        };
      },
      invalidatesTags: ['settings'],
      transformResponse: (response = {}) => response?.data || response,
    }),
  }),
});

export const {
  useGetSettingsQuery,
  useActivateFeatureMutation,
  useDeactivateFeatureMutation,
} = settingsAPIs;
