import { connect } from 'react-redux';
import AutofillModal from './AutofillModal';

function mapStateToProps() {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AutofillModal);
