import { connect } from 'react-redux';
import StoreRoute from './StoreRoute';

function mapStateToProps(state) {
  return {
    storeUUID: state.shop.storeUUID,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StoreRoute);
