const { createReducer } = require('reduxsauce');
const settingsActions = require('./settings.actions');
const initialState = require('./settings.initialState');
const stringHelper = require('helpers/string').default;
const _ = require('lodash');
const i18n = require('i18n').default;

const getShopTasks = (state) => ({ ...state, loading: true });
const getShopTasksSuccess = (state, action) => ({
  ...state,
  tasksList: action.tasks.data,
  loading: false,
});
const getShopTasksFailure = (state) => {
  return {
    ...state,
    loading: false,
  };
};

const completeShopTask = (state, action) => {
  let tasksList = { ...state.tasksList };
  tasksList[action.params.taskId] = true;
  return { ...state, tasksList: { ...tasksList }, loading: false };
};
const completeShopTaskSuccess = (state) => ({
  ...state,
  loading: false,
});
const completeShopTaskFailure = (state) => ({
  ...state,
  loading: false,
});

const getSettings = (state) => ({
  ...state,
  loadingSettings: true,
});
const getSettingsSuccess = (state, action) => ({
  ...state,
  featureList: action.features.data.feature_group,
  loadingSettings: false,
});
const getSettingsFailure = (state) => ({
  ...state,
  loadingSettings: false,
});

const activateFeature = (state, action) => {
  return {
    ...state,
    loadingFeatureId: action.featureId,
    featureError: null,
    loadingThankYouPage: true,
  };
};

const resetSettingsToaster = (state) => {
  return {
    ...state,
    settingsToaster: { showToast: false, isErrorToast: false, toastText: null },
  };
};

const resetKlaviyoConfig = (state) => {
  return {
    ...state,
    klaviyoConfig: { ...state.klaviyoConfig, is_active: false },
  };
};

const resetOmnisendConfig = (state) => {
  return {
    ...state,
    omnisendConfig: { ...state.omnisendConfig, is_active: false },
  };
};

const resetFeatureRequest = (state) => {
  return {
    ...state,
    featureRequest: false,
  };
};

const activateFeatureSuccess = (state, action) => {
  const featureResponse = action.feature.data;
  const tempFeatureList = state.featureList
    .map((featureCategory) => {
      const tempFeatures = featureCategory.features.map((feature) => {
        if (feature.feature_id !== featureResponse.feature_id) {
          return feature;
        } else {
          return featureResponse;
        }
      });
      return { ...featureCategory, features: tempFeatures };
    })
    .filter((item) => !_.isUndefined(item));

  return {
    ...state,
    featureList: tempFeatureList,
    loadingFeatureId: null,
    featureError: null,
    settingsToaster: {
      showToast: true,
      isErrorToast: false,
      toastText: i18n.t('settings.feature.enable.toast'),
    },
    featureRequest: true,
  };
};
const activateFeatureFailure = (state, action) => ({
  ...state,
  loadingFeatureId: null,
  featureError: stringHelper.getErrorText(action),
});

const deactivateFeature = (state, action) => ({
  ...state,
  loadingFeatureId: action.featureId,
  featureError: null,
  loadingThankYouPage: true,
});
const deactivateFeatureSuccess = (state, action) => {
  const featureResponse = action.feature.data;
  const tempFeatureList = state.featureList
    .map((featureCategory) => {
      const tempFeatures = featureCategory.features.map((feature) => {
        if (feature.feature_id !== featureResponse.feature_id) {
          return feature;
        } else {
          return featureResponse;
        }
      });
      return { ...featureCategory, features: tempFeatures };
    })
    .filter((item) => !_.isUndefined(item));
  return {
    ...state,
    featureList: tempFeatureList,
    loadingFeatureId: null,
    featureError: null,
    settingsToaster: {
      showToast: true,
      isErrorToast: false,
      toastText: i18n.t('settings.feature.disable.toast'),
    },
    featureRequest: true,
  };
};
const deactivateFeatureFailure = (state, action) => ({
  ...state,
  loadingFeatureId: null,
  featureError: stringHelper.getErrorText(action),
});

const handleShowSlackModal = (state) => {
  return {
    ...state,
    showSlackModal: !state.showSlackModal,
    slackConfig: {},
    slackConfigSaved: false,
  };
};

const getSlack = (state) => ({ ...state, loading: true });
const getSlackSuccess = (state, action) => ({
  ...state,
  slackConfig: action.config.data.config,
  slackMeta: action.config.data.meta,
  loading: false,
});
const getSlackFailure = (state, action) => {
  return {
    ...state,
    slackError: stringHelper.getErrorText(action),
    loading: false,
  };
};

const saveSlack = (state) => ({ ...state, loading: true });
const saveSlackSuccess = (state, action) => ({
  ...state,
  slackConfig: action.config.data.config,
  slackMeta: action.config.data.meta,
  slackConfigSaved: true,
  loading: false,
});
const saveSlackFailure = (state, action) => {
  return {
    ...state,
    slackError: stringHelper.getErrorText(action),
    loading: false,
  };
};

const testSlack = (state) => ({ ...state, loading: true });
const testSlackSuccess = (state) => ({
  ...state,
  slackTestingConfirmation: true,
  loading: false,
});
const testSlackFailure = (state, action) => ({
  ...state,
  slackError: stringHelper.getErrorText(action),
  loading: false,
});
const resetSlack = (state) => ({
  ...state,
  loading: false,
  slackConfigSaved: false,
  slackTestingConfirmation: false,
  slackError: null,
});

const getKlaviyo = (state) => ({ ...state, loading: true });
const getKlaviyoSuccess = (state, action) => {
  return {
    ...state,
    klaviyoConfig: action.config.data.configuration,
    klaviyoMeta: action.config.data.meta,
    loading: false,
  };
};
const getKlaviyoFailure = (state, action) => {
  return {
    ...state,
    klaviyoError: stringHelper.getErrorText(action),
    loading: false,
  };
};

const saveKlaviyo = (state) => ({ ...state, loading: true });
const saveKlaviyoSuccess = (state, action) => ({
  ...state,
  klaviyoConfig: action.config.data.config,
  klaviyoMeta: action.config.data.meta,
  klaviyoConfigSaved: true,
  loading: false,
  settingsToaster: {
    showToast: true,
    isErrorToast: false,
    toastText: i18n.t('klaviyo.modal.save_success'),
  },
});
const saveKlaviyoFailure = (state, action) => {
  const error = stringHelper.getErrorText(action);
  return {
    ...state,
    klaviyoError: error,
    loading: false,
    settingsToaster: {
      showToast: true,
      isErrorToast: true,
      toastText: error,
    },
  };
};

const testKlaviyo = (state) => ({ ...state, loading: true });
const testKlaviyoSuccess = (state) => ({
  ...state,
  klaviyoTestingConfirmation: true,
  loading: false,
});
const testKlaviyoFailure = (state, action) => ({
  ...state,
  klaviyoError: stringHelper.getErrorText(action),
  loading: false,
});

const sendAllEventsTestKlaviyo = (state) => ({
  ...state,
  loading: true,
});
const sendAllEventsTestKlaviyoSuccess = (state, action) => {
  return {
    ...state,
    settingsToaster: {
      showToast: true,
      isErrorToast: false,
      toastText: action.message,
    },
    klaviyoTestingConfirmation: true,
    loading: false,
  };
};
const sendAllEventsTestKlaviyoFailure = (state, action) => {
  return {
    ...state,
    settingsToaster: {
      showToast: true,
      isErrorToast: true,
      toastText: stringHelper.getErrorText(action),
    },
    loading: false,
  };
};

const resetKlaviyo = (state) => {
  return {
    ...state,
    loading: false,
    klaviyoConfigSaved: false,
    klaviyoTestingConfirmation: false,
    klaviyoError: null,
  };
};

const getOmnisend = (state) => ({ ...state, loading: true });
const getOmnisendSuccess = (state, action) => ({
  ...state,
  omnisendConfig: action.config.data.configuration,
  omnisendMeta: action.config.data.meta,
  loading: false,
});
const getOmnisendFailure = (state, action) => {
  return {
    ...state,
    omnisendError: stringHelper.getErrorText(action),
    loading: false,
  };
};

const saveOmnisend = (state) => ({ ...state, loading: true });
const saveOmnisendSuccess = (state, action) => ({
  ...state,
  omnisendConfig: action.config.data.config,
  omnisendMeta: action.config.data.meta,
  omnisendConfigSaved: true,
  loading: false,
  settingsToaster: {
    showToast: true,
    isErrorToast: false,
    toastText: i18n.t('omnisend.modal.save_success'),
  },
});
const saveOmnisendFailure = (state, action) => {
  const error = stringHelper.getErrorText(action);
  return {
    ...state,
    omnisendError: error,
    loading: false,
    settingsToaster: {
      showToast: true,
      isErrorToast: true,
      toastText: error,
    },
  };
};

const testOmnisend = (state) => ({ ...state, loading: true });
const testOmnisendSuccess = (state, action) => {
  return {
    ...state,
    omnisendTestingConfirmation: true,
    omnisendTestingConfirmationMessage: action.message,
    settingsToaster: {
      showToast: true,
      isErrorToast: false,
      toastText: action.message,
    },
    loading: false,
  };
};
const testOmnisendFailure = (state, action) => {
  const error = stringHelper.getErrorText(action);
  return {
    ...state,
    omnisendError: error,
    settingsToaster: {
      showToast: true,
      isErrorToast: true,
      toastText: error,
    },
    loading: false,
  };
};

const resetOmnisend = (state) => {
  return {
    ...state,
    loading: false,
    omnisendConfigSaved: false,
    omnisendTestingConfirmation: false,
    omnisendTestingConfirmationMessage: null,
    omnisendError: null,
  };
};

const getEmailConfig = (state) => ({
  ...state,
  loading: true,
  emailTriggerCreated: null,
  emailTriggerDetailSaved: null,
});
const getEmailConfigSuccess = (state, action) => ({
  ...state,
  emailConfig: action.config.data.config,
  emailTriggers: action.config.data.triggers,
  loading: false,
});
const getEmailConfigFailure = (state, action) => ({
  ...state,
  emailError: stringHelper.getErrorText(action),
  loading: false,
});

const resetEmailToaster = (state) => ({
  ...state,
  emailToaster: { showToast: false, isErrorToast: null, toastText: null },
  loading: false,
});

const resetEmailTriggerDetail = (state) => ({
  ...state,
  emailTriggerDetail: null,
  emailTriggerDetailDeleted: null,
  loading: true,
});

const getEmailTriggersMeta = (state) => ({
  ...state,
  loading: true,
});
const getEmailTriggersMetaSuccess = (state, action) => ({
  ...state,
  emailTriggerMeta: action.meta,
  loading: false,
});
const getEmailTriggersMetaFailure = (state, action) => ({
  ...state,
  emailError: stringHelper.getErrorText(action),
  loading: false,
});

const postEmailTrigger = (state) => ({
  ...state,
  loading: true,
});
const postEmailTriggerSuccess = (state, action) => {
  return {
    ...state,
    emailToaster: {
      showToast: true,
      isErrorToast: false,
      toastText: i18n.t('emails.trigger_page.toaster.rule_saved'),
    },
    emailTriggerDetail: action.config,
    loading: false,
    emailTriggerCreated: true,
  };
};
const postEmailTriggerFailure = (state) => ({
  ...state,
  emailTriggerCreated: false,
  loading: false,
});

const putEmailTriggerById = (state) => ({
  ...state,
  loading: true,
});
const putEmailTriggerByIdSuccess = (state, action) => {
  return {
    ...state,
    emailToaster: {
      showToast: true,
      isErrorToast: false,
      toastText: i18n.t('emails.trigger_page.toaster.rule_updated'),
    },
    emailTriggerDetail: action.config,
    emailTriggerDetailSaved: true,
    loading: false,
  };
};
const putEmailTriggerByIdFailure = (state) => ({
  ...state,
  emailTriggerDetailSaved: false,
  loading: false,
});

const getEmailTriggerById = (state) => ({
  ...state,
  emailTriggerLoading: true,
  emailTriggerCreated: null,
  emailTriggerDetailSaved: null,
});
const getEmailTriggerByIdSuccess = (state, action) => ({
  ...state,
  emailTriggerDetail: action.config,
  emailTriggerLoading: false,
  loading: false,
});
const getEmailTriggerByIdFailure = (state) => ({
  ...state,
  emailTriggerDetail: null,
  emailTriggerLoading: false,
  loading: false,
});

const getEmailTemplatePreview = (state) => ({
  ...state,
  loading: true,
});
const getEmailTemplatePreviewSuccess = (state, action) => ({
  ...state,
  emailTemplatePreview: action.template,
  loading: false,
});
const getEmailTemplatePreviewFailure = (state) => ({
  ...state,
  emailTemplatePreview: null,
  loading: false,
});

const resetEmailTemplatePreview = (state) => ({
  ...state,
  emailTemplatePreview: null,
  loading: false,
});

const deleteEmailTriggerById = (state) => ({
  ...state,
  loading: true,
});
const deleteEmailTriggerByIdSuccess = (state, action) => {
  let tempEmailTriggers = state.emailTriggers
    .map((trigger) => {
      return trigger.id === action.triggerId ? undefined : trigger;
    })
    .filter((item) => !_.isUndefined(item));
  return {
    ...state,
    emailToaster: {
      showToast: true,
      isErrorToast: false,
      toastText: i18n.t(
        'email.page.card.notification_events.trigger_deleted_success'
      ),
    },
    emailTriggers: tempEmailTriggers,
    emailTriggerDetailDeleted: true,
    loading: false,
  };
};
const deleteEmailTriggerByIdFailure = (state) => {
  return {
    ...state,
    emailToaster: {
      showToast: true,
      isErrorToast: true,
      toastText: i18n.t(
        'email.page.card.notification_events.trigger_deleted_failure'
      ),
    },
    loading: false,
  };
};

const testEmailTriggerById = (state) => ({
  ...state,
  loading: true,
});
const testEmailTriggerByIdSuccess = (state, action) => {
  return {
    ...state,
    emailToaster: {
      showToast: true,
      isErrorToast: false,
      toastText: action.message,
    },
    loading: false,
  };
};
const testEmailTriggerByIdFailure = (state) => {
  return {
    ...state,
    emailToaster: {
      showToast: true,
      isErrorToast: true,
      toastText: i18n.t(
        'email.page.card.notification_events.trigger_test_failure'
      ),
    },
    loading: false,
  };
};

const activateEmailTrigger = (state) => ({
  ...state,
  loading: true,
});
const activateEmailTriggerSuccess = (state, action) => {
  const tempTriggers = state.emailTriggers.map((trigger) => {
    if (trigger.id === action.trigger.id) {
      return action.trigger;
    } else {
      return trigger;
    }
  });
  return {
    ...state,
    emailTriggers: tempTriggers,
    emailToaster: {
      showToast: true,
      isErrorToast: false,
      toastText: i18n.t(
        'email.page.card.notification_events.trigger_toaster_enable',
        {
          email_summary: action.trigger.summary.split('**').join(''),
        }
      ),
    },
    loading: false,
  };
};
const activateEmailTriggerFailure = (state) => {
  return {
    ...state,
    emailToaster: {
      showToast: true,
      isErrorToast: true,
      toastText: i18n.t(
        'email.page.card.notification_events.trigger_toaster_error'
      ),
    },
    loading: false,
  };
};

const deactivateEmailTrigger = (state) => ({
  ...state,
  loading: true,
});
const deactivateEmailTriggerSuccess = (state, action) => {
  const tempTriggers = state.emailTriggers.map((trigger) => {
    if (trigger.id === action.trigger.id) {
      return action.trigger;
    } else {
      return trigger;
    }
  });
  return {
    ...state,
    emailToaster: {
      showToast: true,
      isErrorToast: false,
      toastText: i18n.t(
        'email.page.card.notification_events.trigger_toaster_disable',
        {
          email_summary: action.trigger.summary.split('**').join(''),
        }
      ),
    },
    emailTriggers: tempTriggers,
    loading: false,
  };
};
const deactivateEmailTriggerFailure = (state) => {
  return {
    ...state,
    emailToaster: {
      showToast: true,
      isErrorToast: true,
      toastText: i18n.t(
        'email.page.card.notification_events.trigger_toaster_error'
      ),
    },
    loading: false,
  };
};

const getDashboardTasks = (state) => ({
  ...state,
  dashboardLoading: true,
});
const getDashboardTasksSuccess = (state, action) => ({
  ...state,
  dashboardTasks: action.tasks,
  dashboardLoading: false,
});
const getDashboardTasksFailure = (state) => ({
  ...state,
  dashboardLoading: false,
});

const dismissDashboardTasks = (state, action) => {
  return {
    ...state,
    dashboardLoading: true,
    dashboardTasks: state.dashboardTasks
      .map((task) => {
        if (task.task_id === action.taskId) {
          return undefined;
        }
        return task;
      })
      .filter((item) => !_.isUndefined(item)),
  };
};
const dismissDashboardTasksSuccess = (state, action) => {
  return {
    ...state,
    dashboardToaster: {
      showToast: true,
      isErrorToast: false,
      toastText: action.message,
    },
    dashboardLoading: false,
  };
};
const dismissDashboardTasksFailure = (state) => ({
  ...state,
  dashboardLoading: false,
});

const completeDashboardTasks = (state) => ({
  ...state,
  dashboardLoading: true,
});
const completeDashboardTasksSuccess = (state, action) => {
  return {
    ...state,
    dashboardToaster: {
      showToast: true,
      isErrorToast: false,
      toastText: action.message,
    },
    dashboardLoading: false,
  };
};
const completeDashboardTasksFailure = (state) => ({
  ...state,
  dashboardLoading: false,
});

const resetDashboardTasks = (state) => {
  return {
    ...state,
    dashboardLoading: false,
    dashboardToaster: {
      showToast: false,
      isErrorToast: null,
      toastText: null,
    },
  };
};

const updateEmailSender = (state) => ({
  ...state,
  loading: true,
});
const updateEmailSenderSuccess = (state, action) => ({
  ...state,
  emailConfig: action.response,
  emailToaster: {
    showToast: true,
    isErrorToast: false,
    toastText: i18n.t('email.page.bcc_email.text_field_updated'),
  },
  loading: false,
});
const updateEmailSenderFailure = (state) => ({
  ...state,
  dashboardLoading: false,
});

const getPaypalConfig = (state) => ({
  ...state,
  paypalLoading: true,
});
const getPaypalConfigSuccess = (state, action) => ({
  ...state,
  paypalLoading: false,
  paypalSyncTrackingConfig: action.response.sync_tracking_config,
  paypalButtonConfiguration: action.response.paypal_button_configuration,
  paypalDisputeResolutionConfig: action.response.dispute_resolution_config,
  paypalMetaTrackingNumberType: action.response.meta.tracking_number_type,
  paypalMetaUpdateMode: action.response.meta.update_mode,
});
const getPaypalConfigFailure = (state, action) => {
  return {
    ...state,
    paypalLoading: false,
    paypalError: stringHelper.getErrorText(action),
  };
};

const putPaypalConfig = (state) => {
  return { ...state, paypalLoading: true };
};
const putPaypalConfigSuccess = (state, action) => ({
  ...state,
  paypalLoading: false,
  paypalSyncTrackingConfig: action.response.sync_tracking_config,
  paypalButtonConfiguration: action.response.paypal_button_configuration,
  paypalDisputeResolutionConfig: action.response.dispute_resolution_config,
  paypalMetaTrackingNumberType: action.response.meta.tracking_number_type,
  paypalMetaUpdateMode: action.response.meta.update_mode,
  paypalToaster: {
    showToast: true,
    isErrorToast: false,
    toastText: i18n.t('settings.paypal_message.configuration_updated'),
  },
});
const putPaypalConfigFailure = (state) => ({
  ...state,
  paypalLoading: false,
});

const postPaypalAccount = (state) => ({
  ...state,
  paypalLoading: true,
});
const postPaypalAccountSuccess = (state) => {
  return {
    ...state,
    paypalLoading: true,
    paypalAccountsUpdated: true,
    paypalToaster: {
      showToast: true,
      isErrorToast: false,
      toastText: i18n.t('settings.paypal_message.configuration_updated'),
    },
  };
};
const postPaypalAccountFailure = (state) => {
  return {
    ...state,
    paypalLoading: false,
    paypalAccountsUpdated: false,
    paypalToaster: {
      showToast: true,
      isErrorToast: true,
      toastText: i18n.t('settings.paypal_message.account_connected_fail'),
    },
  };
};

const putPaypalAccount = (state) => ({
  ...state,
  paypalLoading: true,
});
const putPaypalAccountSuccess = (state) => {
  return {
    ...state,
    paypalLoading: true,
    paypalAccountsUpdated: true,
    paypalToaster: {
      showToast: true,
      isErrorToast: false,
      toastText: i18n.t('settings.paypal_message.account_updated'),
    },
  };
};
const putPaypalAccountFailure = (state) => {
  return {
    ...state,
    paypalLoading: false,
    paypalAccountsUpdated: false,
    paypalToaster: {
      showToast: true,
      isErrorToast: true,
      toastText: i18n.t('settings.paypal_message.account_updated_fail'),
    },
  };
};

const deletePaypalAccount = (state) => ({
  ...state,
  paypalLoading: true,
});
const deletePaypalAccountSuccess = (state) => {
  return {
    ...state,
    paypalLoading: true,
    paypalAccountsUpdated: true,
    paypalToaster: {
      showToast: true,
      isErrorToast: false,
      toastText: i18n.t('settings.paypal_message.account_deleted'),
    },
  };
};
const deletePaypalAccountFailure = (state) => {
  return {
    ...state,
    paypalLoading: false,
    paypalAccountsUpdated: false,
    paypalToaster: {
      showToast: true,
      isErrorToast: true,
      toastText: i18n.t('settings.paypal_message.account_deleted_fail'),
    },
  };
};

const resetPaypalToaster = (state) => {
  return {
    ...state,
    paypalToaster: {
      showToast: false,
      isErrorToast: null,
      toastText: null,
    },
    paypalAccountsUpdated: null,
    paypalLoading: false,
    paypalExportLoading: false,
    paypalTrackingsExportMessage: null,
  };
};

const getPaypalTrackings = (state) => ({
  ...state,
  paypalLoading: true,
});
const getPaypalTrackingsSuccess = (state, action) => {
  return {
    ...state,
    paypalLoading: false,
    paypalTrackingsData: action.response.data,
    paypalTrackingsMeta: action.response.meta,
    paypalTrackingsSummary: action.response.summary,
  };
};
const getPaypalTrackingsFailure = (state) => ({
  ...state,
  paypalLoading: false,
});

const exportPaypalTrackings = (state) => ({
  ...state,
  paypalLoading: true,
  paypalExportLoading: true,
});
const exportPaypalTrackingsSuccess = (state, action) => {
  return {
    ...state,
    paypalTrackingsExportMessage: action.response.data.message,
    paypalLoading: false,
    paypalExportLoading: false,
    paypalToaster: {
      showToast: true,
      isErrorToast: false,
      toastText: action.response.data.message,
    },
  };
};
const exportPaypalTrackingsFailure = (state) => ({
  ...state,
  paypalLoading: false,
  paypalExportLoading: false,
});
const showUnsavedModal = (state, action) => ({
  ...state,
  unsavedModalOpen: !state.unsavedModalOpen,
  unsavedModalFns: action.callbackFns,
});

const getShipBobConfig = (state) => ({
  ...state,
  shipBobLoading: true,
});
const getShipBobConfigSuccess = (state, action) => ({
  ...state,
  shipBobLoading: false,
  shipBobInstallLink: action.response.auth_link,
  shipBobAccounts: action.response.accounts,
});
const getShipBobConfigFailure = (state) => ({
  ...state,
  shipBobLoading: false,
});

const deleteShipBobAccount = (state) => ({
  ...state,
  shipBobLoading: true,
});
const deleteShipBobAccountSuccess = (state, action) => ({
  ...state,
  shipBobLoading: false,
  shipbobToaster: {
    showToast: true,
    isErrorToast: false,
    toastText: action.response.message,
  },
});
const deleteShipBobAccountFailure = (state) => ({
  ...state,
  shipBobLoading: false,
});

const resetShipBobToaster = (state) => {
  return {
    ...state,
    shipbobToaster: {
      showToast: false,
      isErrorToast: null,
      toastText: null,
    },
  };
};
const setShipBobToaster = (state, action) => {
  return {
    ...state,
    shipbobToaster: {
      showToast: true,
      isErrorToast: false,
      toastText: action.message,
    },
  };
};

const getOrderStatusPageConfigurations = (state) => ({
  ...state,
  loadingThankYouPage: true,
});
const getOrderStatusPageConfigurationsSuccess = (state, action) => ({
  ...state,
  orderStatusConfigurations: action.response.configuration,
  orderStatusMeta: action.response.meta,
  loadingThankYouPage: false,
});
const getOrderStatusPageConfigurationsFailure = (state) => ({
  ...state,
  loadingThankYouPage: false,
});

const getKlaviyoSummaryFlow = (state, action) => ({
  ...state,
  payload: action.payload || '',
  loadingFlow: true,
  loadingFlowError: false,
});
const getKlaviyoSummaryFlowSuccess = (state, action) => ({
  ...state,
  klaviyoFlowSummary: action.response,
  loadingFlow: false,
  loadingFlowError: false,
});
const getKlaviyoSummaryFlowFailure = (state, action) => ({
  ...state,
  loadingFlow: false,
  loadingFlowError: true,
  flowSummaryError: stringHelper.getErrorText(action),
  klaviyoFlowSummary: [],
});

const putOrderStatusPageConfigurations = (state) => ({
  ...state,
});
const putOrderStatusPageConfigurationsSuccess = (state, action) => ({
  ...state,
  orderStatusConfigurations: action.response.configuration,
  orderStatusMeta: action.response.meta,
  settingsToaster: {
    showToast: true,
    isErrorToast: false,
    toastText: i18n.t('settings.thankyou.toaster.success'),
  },
});
const putOrderStatusPageConfigurationsFailure = (state) => ({
  ...state,
});

const getOrderStatusPagePreviewLink = (state) => ({
  ...state,
  loadingOrderPagePreviewLink: true,
});
const getOrderStatusPagePreviewLinkSuccess = (state, action) => ({
  ...state,
  orderStatusPagePreviewLink: action.response.link,
  loadingOrderPagePreviewLink: false,
});
const getOrderStatusPagePreviewLinkFailure = (state, action) => ({
  ...state,
  loadingOrderPagePreviewLink: false,
  settingsToaster: {
    showToast: true,
    isErrorToast: true,
    toastText: stringHelper.getErrorText(action),
  },
});

const { Types } = settingsActions;

// map our action types to our reducer functions
const HANDLERS = {
  [Types.GET_SHOP_TASKS]: getShopTasks,
  [Types.GET_SHOP_TASKS_SUCCESS]: getShopTasksSuccess,
  [Types.GET_SHOP_TASKS_FAILURE]: getShopTasksFailure,

  [Types.COMPLETE_SHOP_TASK]: completeShopTask,
  [Types.COMPLETE_SHOP_TASK_SUCCESS]: completeShopTaskSuccess,
  [Types.COMPLETE_SHOP_TASK_FAILURE]: completeShopTaskFailure,

  [Types.GET_SETTINGS]: getSettings,
  [Types.GET_SETTINGS_SUCCESS]: getSettingsSuccess,
  [Types.GET_SETTINGS_FAILURE]: getSettingsFailure,

  [Types.ACTIVATE_FEATURE]: activateFeature,
  [Types.ACTIVATE_FEATURE_SUCCESS]: activateFeatureSuccess,
  [Types.ACTIVATE_FEATURE_FAILURE]: activateFeatureFailure,
  [Types.RESET_SETTINGS_TOASTER]: resetSettingsToaster,
  [Types.RESET_KLAVIYO_CONFIG]: resetKlaviyoConfig,
  [Types.RESET_OMNISEND_CONFIG]: resetOmnisendConfig,

  [Types.RESET_FEATURE_REQUEST]: resetFeatureRequest,
  [Types.DEACTIVATE_FEATURE]: deactivateFeature,
  [Types.DEACTIVATE_FEATURE_SUCCESS]: deactivateFeatureSuccess,
  [Types.DEACTIVATE_FEATURE_FAILURE]: deactivateFeatureFailure,

  [Types.GET_SLACK]: getSlack,
  [Types.GET_SLACK_SUCCESS]: getSlackSuccess,
  [Types.GET_SLACK_FAILURE]: getSlackFailure,

  [Types.SAVE_SLACK]: saveSlack,
  [Types.SAVE_SLACK_SUCCESS]: saveSlackSuccess,
  [Types.SAVE_SLACK_FAILURE]: saveSlackFailure,

  [Types.TEST_SLACK]: testSlack,
  [Types.TEST_SLACK_SUCCESS]: testSlackSuccess,
  [Types.TEST_SLACK_FAILURE]: testSlackFailure,

  [Types.RESET_SLACK]: resetSlack,

  [Types.HANDLE_SHOW_SLACK_MODAL]: handleShowSlackModal,

  [Types.GET_KLAVIYO]: getKlaviyo,
  [Types.GET_KLAVIYO_SUCCESS]: getKlaviyoSuccess,
  [Types.GET_KLAVIYO_FAILURE]: getKlaviyoFailure,

  [Types.GET_KLAVIYO_SUMMARY_FLOW]: getKlaviyoSummaryFlow,
  [Types.GET_KLAVIYO_SUMMARY_FLOW_SUCCESS]: getKlaviyoSummaryFlowSuccess,
  [Types.GET_KLAVIYO_SUMMARY_FLOW_FAILURE]: getKlaviyoSummaryFlowFailure,

  [Types.SAVE_KLAVIYO]: saveKlaviyo,
  [Types.SAVE_KLAVIYO_SUCCESS]: saveKlaviyoSuccess,
  [Types.SAVE_KLAVIYO_FAILURE]: saveKlaviyoFailure,

  [Types.TEST_KLAVIYO]: testKlaviyo,
  [Types.TEST_KLAVIYO_SUCCESS]: testKlaviyoSuccess,
  [Types.TEST_KLAVIYO_FAILURE]: testKlaviyoFailure,

  [Types.SEND_ALL_EVENTS_TEST_KLAVIYO]: sendAllEventsTestKlaviyo,
  [Types.SEND_ALL_EVENTS_TEST_KLAVIYO_SUCCESS]: sendAllEventsTestKlaviyoSuccess,
  [Types.SEND_ALL_EVENTS_TEST_KLAVIYO_FAILURE]: sendAllEventsTestKlaviyoFailure,

  [Types.RESET_KLAVIYO]: resetKlaviyo,
  [Types.GET_OMNISEND]: getOmnisend,
  [Types.GET_OMNISEND_SUCCESS]: getOmnisendSuccess,
  [Types.GET_OMNISEND_FAILURE]: getOmnisendFailure,

  [Types.SAVE_OMNISEND]: saveOmnisend,
  [Types.SAVE_OMNISEND_SUCCESS]: saveOmnisendSuccess,
  [Types.SAVE_OMNISEND_FAILURE]: saveOmnisendFailure,

  [Types.TEST_OMNISEND]: testOmnisend,
  [Types.TEST_OMNISEND_SUCCESS]: testOmnisendSuccess,
  [Types.TEST_OMNISEND_FAILURE]: testOmnisendFailure,

  [Types.RESET_OMNISEND]: resetOmnisend,

  [Types.GET_EMAIL_CONFIG]: getEmailConfig,
  [Types.GET_EMAIL_CONFIG_SUCCESS]: getEmailConfigSuccess,
  [Types.GET_EMAIL_CONFIG_FAILURE]: getEmailConfigFailure,

  [Types.ACTIVATE_EMAIL_TRIGGER]: activateEmailTrigger,
  [Types.ACTIVATE_EMAIL_TRIGGER_SUCCESS]: activateEmailTriggerSuccess,
  [Types.ACTIVATE_EMAIL_TRIGGER_FAILURE]: activateEmailTriggerFailure,

  [Types.DEACTIVATE_EMAIL_TRIGGER]: deactivateEmailTrigger,
  [Types.DEACTIVATE_EMAIL_TRIGGER_SUCCESS]: deactivateEmailTriggerSuccess,
  [Types.DEACTIVATE_EMAIL_TRIGGER_FAILURE]: deactivateEmailTriggerFailure,

  [Types.RESET_EMAIL_TOASTER]: resetEmailToaster,

  [Types.GET_EMAIL_TRIGGERS_META]: getEmailTriggersMeta,
  [Types.GET_EMAIL_TRIGGERS_META_SUCCESS]: getEmailTriggersMetaSuccess,
  [Types.GET_EMAIL_TRIGGERS_META_FAILURE]: getEmailTriggersMetaFailure,

  [Types.POST_EMAIL_TRIGGER]: postEmailTrigger,
  [Types.POST_EMAIL_TRIGGER_SUCCESS]: postEmailTriggerSuccess,
  [Types.POST_EMAIL_TRIGGER_FAILURE]: postEmailTriggerFailure,

  [Types.PUT_EMAIL_TRIGGER_BY_ID]: putEmailTriggerById,
  [Types.PUT_EMAIL_TRIGGER_BY_ID_SUCCESS]: putEmailTriggerByIdSuccess,
  [Types.PUT_EMAIL_TRIGGER_BY_ID_FAILURE]: putEmailTriggerByIdFailure,

  [Types.GET_EMAIL_TRIGGER_BY_ID]: getEmailTriggerById,
  [Types.GET_EMAIL_TRIGGER_BY_ID_SUCCESS]: getEmailTriggerByIdSuccess,
  [Types.GET_EMAIL_TRIGGER_BY_ID_FAILURE]: getEmailTriggerByIdFailure,

  [Types.DELETE_EMAIL_TRIGGER_BY_ID]: deleteEmailTriggerById,
  [Types.DELETE_EMAIL_TRIGGER_BY_ID_SUCCESS]: deleteEmailTriggerByIdSuccess,
  [Types.DELETE_EMAIL_TRIGGER_BY_ID_FAILURE]: deleteEmailTriggerByIdFailure,

  [Types.TEST_EMAIL_TRIGGER_BY_ID]: testEmailTriggerById,
  [Types.TEST_EMAIL_TRIGGER_BY_ID_SUCCESS]: testEmailTriggerByIdSuccess,
  [Types.TEST_EMAIL_TRIGGER_BY_ID_FAILURE]: testEmailTriggerByIdFailure,

  [Types.RESET_EMAIL_TEMPLATE_PREVIEW]: resetEmailTemplatePreview,
  [Types.GET_EMAIL_TEMPLATE_PREVIEW]: getEmailTemplatePreview,
  [Types.GET_EMAIL_TEMPLATE_PREVIEW_SUCCESS]: getEmailTemplatePreviewSuccess,
  [Types.GET_EMAIL_TEMPLATE_PREVIEW_FAILURE]: getEmailTemplatePreviewFailure,

  [Types.UPDATE_EMAIL_SENDER]: updateEmailSender,
  [Types.UPDATE_EMAIL_SENDER_SUCCESS]: updateEmailSenderSuccess,
  [Types.UPDATE_EMAIL_SENDER_FAILURE]: updateEmailSenderFailure,

  [Types.RESET_EMAIL_TRIGGER_DETAIL]: resetEmailTriggerDetail,

  [Types.GET_DASHBOARD_TASKS]: getDashboardTasks,
  [Types.GET_DASHBOARD_TASKS_SUCCESS]: getDashboardTasksSuccess,
  [Types.GET_DASHBOARD_TASKS_FAILURE]: getDashboardTasksFailure,

  [Types.DISMISS_DASHBOARD_TASKS]: dismissDashboardTasks,
  [Types.DISMISS_DASHBOARD_TASKS_SUCCESS]: dismissDashboardTasksSuccess,
  [Types.DISMISS_DASHBOARD_TASKS_FAILURE]: dismissDashboardTasksFailure,

  [Types.COMPLETE_DASHBOARD_TASKS]: completeDashboardTasks,
  [Types.COMPLETE_DASHBOARD_TASKS_SUCCESS]: completeDashboardTasksSuccess,
  [Types.COMPLETE_DASHBOARD_TASKS_FAILURE]: completeDashboardTasksFailure,

  [Types.GET_PAYPAL_CONFIG]: getPaypalConfig,
  [Types.GET_PAYPAL_CONFIG_SUCCESS]: getPaypalConfigSuccess,
  [Types.GET_PAYPAL_CONFIG_FAILURE]: getPaypalConfigFailure,

  [Types.PUT_PAYPAL_CONFIG]: putPaypalConfig,
  [Types.PUT_PAYPAL_CONFIG_SUCCESS]: putPaypalConfigSuccess,
  [Types.PUT_PAYPAL_CONFIG_FAILURE]: putPaypalConfigFailure,

  [Types.POST_PAYPAL_ACCOUNT]: postPaypalAccount,
  [Types.POST_PAYPAL_ACCOUNT_SUCCESS]: postPaypalAccountSuccess,
  [Types.POST_PAYPAL_ACCOUNT_FAILURE]: postPaypalAccountFailure,

  [Types.PUT_PAYPAL_ACCOUNT]: putPaypalAccount,
  [Types.PUT_PAYPAL_ACCOUNT_SUCCESS]: putPaypalAccountSuccess,
  [Types.PUT_PAYPAL_ACCOUNT_FAILURE]: putPaypalAccountFailure,

  [Types.DELETE_PAYPAL_ACCOUNT]: deletePaypalAccount,
  [Types.DELETE_PAYPAL_ACCOUNT_SUCCESS]: deletePaypalAccountSuccess,
  [Types.DELETE_PAYPAL_ACCOUNT_FAILURE]: deletePaypalAccountFailure,

  [Types.GET_PAYPAL_TRACKINGS]: getPaypalTrackings,
  [Types.GET_PAYPAL_TRACKINGS_SUCCESS]: getPaypalTrackingsSuccess,
  [Types.GET_PAYPAL_TRACKINGS_FAILURE]: getPaypalTrackingsFailure,

  [Types.EXPORT_PAYPAL_TRACKINGS]: exportPaypalTrackings,
  [Types.EXPORT_PAYPAL_TRACKINGS_SUCCESS]: exportPaypalTrackingsSuccess,
  [Types.EXPORT_PAYPAL_TRACKINGS_FAILURE]: exportPaypalTrackingsFailure,

  [Types.RESET_DASHBOARD_TASKS]: resetDashboardTasks,
  [Types.RESET_PAYPAL_TOASTER]: resetPaypalToaster,
  [Types.SHOW_UNSAVED_MODAL]: showUnsavedModal,
  [Types.RESET_SHIP_BOB_TOASTER]: resetShipBobToaster,
  [Types.SET_SHIP_BOB_TOASTER]: setShipBobToaster,

  [Types.GET_SHIP_BOB_CONFIG]: getShipBobConfig,
  [Types.GET_SHIP_BOB_CONFIG_SUCCESS]: getShipBobConfigSuccess,
  [Types.GET_SHIP_BOB_CONFIG_FAILURE]: getShipBobConfigFailure,

  [Types.DELETE_SHIP_BOB_ACCOUNT]: deleteShipBobAccount,
  [Types.DELETE_SHIP_BOB_ACCOUNT_SUCCESS]: deleteShipBobAccountSuccess,
  [Types.DELETE_SHIP_BOB_ACCOUNT_FAILURE]: deleteShipBobAccountFailure,

  [Types.GET_ORDER_STATUS_PAGE_CONFIGURATIONS]:
    getOrderStatusPageConfigurations,
  [Types.GET_ORDER_STATUS_PAGE_CONFIGURATIONS_SUCCESS]:
    getOrderStatusPageConfigurationsSuccess,
  [Types.GET_ORDER_STATUS_PAGE_CONFIGURATIONS_FAILURE]:
    getOrderStatusPageConfigurationsFailure,

  [Types.PUT_ORDER_STATUS_PAGE_CONFIGURATIONS]:
    putOrderStatusPageConfigurations,
  [Types.PUT_ORDER_STATUS_PAGE_CONFIGURATIONS_SUCCESS]:
    putOrderStatusPageConfigurationsSuccess,
  [Types.PUT_ORDER_STATUS_PAGE_CONFIGURATIONS_FAILURE]:
    putOrderStatusPageConfigurationsFailure,

  [Types.GET_ORDER_STATUS_PAGE_PREVIEW_LINK]: getOrderStatusPagePreviewLink,
  [Types.GET_ORDER_STATUS_PAGE_PREVIEW_LINK_SUCCESS]:
    getOrderStatusPagePreviewLinkSuccess,
  [Types.GET_ORDER_STATUS_PAGE_PREVIEW_LINK_FAILURE]:
    getOrderStatusPagePreviewLinkFailure,
};

module.exports = createReducer(initialState, HANDLERS);
