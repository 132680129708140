const { createActions } = require('reduxsauce');

module.exports = createActions({
  getShopTasks: ['params'],
  getShopTasksSuccess: ['tasks'],
  getShopTasksFailure: ['error'],

  completeShopTask: ['params'],
  completeShopTaskSuccess: ['data', 'taskId'],
  completeShopTaskFailure: null,

  getSettings: ['params'],
  getSettingsSuccess: ['features'],
  getSettingsFailure: ['error'],

  activateFeature: ['featureId', 'flag'],
  activateFeatureSuccess: ['feature'],
  activateFeatureFailure: ['error'],

  deactivateFeature: ['featureId', 'flag'],
  deactivateFeatureSuccess: ['feature'],
  deactivateFeatureFailure: ['error'],

  resetSettingsToaster: null,
  resetKlaviyoConfig: {},
  resetOmnisendConfig: {},

  resetFeatureRequest: null,

  getSlack: ['params'],
  getSlackSuccess: ['config'],
  getSlackFailure: ['error'],

  saveSlack: ['params'],
  saveSlackSuccess: ['config'],
  saveSlackFailure: ['error'],

  testSlack: ['params'],
  testSlackSuccess: ['config'],
  testSlackFailure: ['error'],

  resetSlack: null,

  handleShowSlackModal: null,

  getKlaviyo: ['params'],
  getKlaviyoSuccess: ['config'],
  getKlaviyoFailure: ['error'],

  saveKlaviyo: ['params'],
  saveKlaviyoSuccess: ['config'],
  saveKlaviyoFailure: ['error'],

  testKlaviyo: ['params'],
  testKlaviyoSuccess: ['config'],
  testKlaviyoFailure: ['error'],

  sendAllEventsTestKlaviyo: ['params'],
  sendAllEventsTestKlaviyoSuccess: ['message'],
  sendAllEventsTestKlaviyoFailure: ['error'],

  resetKlaviyo: null,

  getOmnisend: ['params'],
  getOmnisendSuccess: ['config'],
  getOmnisendFailure: ['error'],

  saveOmnisend: ['params'],
  saveOmnisendSuccess: ['config'],
  saveOmnisendFailure: ['error'],

  testOmnisend: ['params'],
  testOmnisendSuccess: ['message'],
  testOmnisendFailure: ['error'],

  resetOmnisend: null,

  getEmailConfig: ['params'],
  getEmailConfigSuccess: ['config'],
  getEmailConfigFailure: ['error'],

  activateEmailTrigger: ['triggerId'],
  activateEmailTriggerSuccess: ['trigger'],
  activateEmailTriggerFailure: ['error'],

  deactivateEmailTrigger: ['triggerId'],
  deactivateEmailTriggerSuccess: ['trigger'],
  deactivateEmailTriggerFailure: ['error'],

  resetEmailToaster: null,
  resetEmailTriggerDetail: null,

  getEmailTriggersMeta: null,
  getEmailTriggersMetaSuccess: ['meta'],
  getEmailTriggersMetaFailure: ['error'],

  postEmailTrigger: ['params'],
  postEmailTriggerSuccess: ['config'],
  postEmailTriggerFailure: ['error'],

  getEmailTriggerById: ['triggerId'],
  getEmailTriggerByIdSuccess: ['config'],
  getEmailTriggerByIdFailure: ['error'],

  putEmailTriggerById: ['triggerId', 'params'],
  putEmailTriggerByIdSuccess: ['config'],
  putEmailTriggerByIdFailure: ['error'],

  deleteEmailTriggerById: ['triggerId'],
  deleteEmailTriggerByIdSuccess: ['triggerId'],
  deleteEmailTriggerByIdFailure: ['error'],

  testEmailTriggerById: ['params'],
  testEmailTriggerByIdSuccess: ['message'],
  testEmailTriggerByIdFailure: ['error'],

  resetEmailTemplatePreview: null,
  getEmailTemplatePreview: ['templateId', 'language'],
  getEmailTemplatePreviewSuccess: ['template'],
  getEmailTemplatePreviewFailure: ['error'],

  getDashboardTasks: ['params'],
  getDashboardTasksSuccess: ['tasks'],
  getDashboardTasksFailure: ['error'],

  dismissDashboardTasks: ['taskId'],
  dismissDashboardTasksSuccess: ['message'],
  dismissDashboardTasksFailure: ['error'],

  completeDashboardTasks: ['taskId'],
  completeDashboardTasksSuccess: ['message'],
  completeDashboardTasksFailure: ['error'],

  updateEmailSender: ['params'],
  updateEmailSenderSuccess: ['response'],
  updateEmailSenderFailure: ['error'],

  getPaypalConfig: ['params'],
  getPaypalConfigSuccess: ['response'],
  getPaypalConfigFailure: ['error'],

  putPaypalConfig: ['params'],
  putPaypalConfigSuccess: ['response'],
  putPaypalConfigFailure: ['response'],

  postPaypalAccount: ['params'],
  postPaypalAccountSuccess: ['response'],
  postPaypalAccountFailure: ['response'],

  putPaypalAccount: ['accountId', 'params'],
  putPaypalAccountSuccess: ['response'],
  putPaypalAccountFailure: ['response'],

  deletePaypalAccount: ['params'],
  deletePaypalAccountSuccess: ['response'],
  deletePaypalAccountFailure: ['response'],

  getPaypalTrackings: ['params'],
  getPaypalTrackingsSuccess: ['response'],
  getPaypalTrackingsFailure: ['response'],

  exportPaypalTrackings: ['params'],
  exportPaypalTrackingsSuccess: ['response'],
  exportPaypalTrackingsFailure: ['response'],

  getShipBobConfig: ['params'],
  getShipBobConfigSuccess: ['response'],
  getShipBobConfigFailure: ['response'],

  deleteShipBobAccount: ['params'],
  deleteShipBobAccountSuccess: ['response'],
  deleteShipBobAccountFailure: ['response'],

  resetDashboardTasks: null,
  resetPaypalToaster: null,
  resetShipBobToaster: null,
  setShipBobToaster: ['message'],

  showUnsavedModal: ['callbackFns'],

  getOrderStatusPageConfigurations: null,
  getOrderStatusPageConfigurationsSuccess: ['response'],
  getOrderStatusPageConfigurationsFailure: null,

  getKlaviyoSummaryFlow: ['payload'],
  getKlaviyoSummaryFlowSuccess: ['response'],
  getKlaviyoSummaryFlowFailure: ['error'],

  putOrderStatusPageConfigurations: ['params'],
  putOrderStatusPageConfigurationsSuccess: ['response'],
  putOrderStatusPageConfigurationsFailure: null,

  getOrderStatusPagePreviewLink: null,
  getOrderStatusPagePreviewLinkSuccess: ['response'],
  getOrderStatusPagePreviewLinkFailure: ['error'],
});
