import { baseApi } from '../../redux/store/baseApi';

export const accountAPIs = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCountryCodes: builder.query({
      query: () => {
        return {
          url: `/meta/country-calling-codes`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    updateUserDetails: builder.mutation({
      query: (data) => {
        return {
          url: `/user/me`,
          method: 'PATCH',
          body: { ...data },
        };
      },
      extraOptions: {
        showToast: true,
      },
      transformResponse: (response = {}) => response?.data || response,
      invalidatesTags: ['userinfo'],
    }),
    changeUserEmail: builder.mutation({
      query: (data) => {
        return {
          url: `/user/me/email`,
          method: 'PATCH',
          body: { ...data },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    changeUserPhone: builder.mutation({
      query: (data) => {
        return {
          url: `/user/me/phone`,
          method: 'PATCH',
          body: { ...data },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    resendCode: builder.mutation({
      query: (token) => {
        return {
          url: `/verifications/resend`,
          method: 'POST',
          body: { token },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    verifyCodeUser: builder.mutation({
      query: ({ token, code }) => {
        return {
          url: `/verifications/code`,
          method: 'POST',
          body: { token, verification_code: code },
          showToast: true,
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    resendVerificationNew: builder.mutation({
      query: (params) => {
        return {
          url: `/verifications/resend/${params.email ? 'email' : 'phone'}`,
          method: 'POST',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
  }),
});

export const {
  useGetCountryCodesQuery,
  useChangeUserEmailMutation,
  useChangeUserPhoneMutation,
  useResendCodeMutation,
  useResendVerificationNewMutation,
  useVerifyCodeUserMutation,
  useUpdateUserDetailsMutation,
} = accountAPIs;
