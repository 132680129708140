const { call, put, takeEvery, select, take } = require('redux-saga/effects');
const trackingActions = require('./tracking.actions');
const trackingWebServices = require('./tracking.webServices');
const { API_URL } = require('config');

const { Types, Creators } = trackingActions;
const {
  getTrackingPageDetail,
  saveTrackingPageDetail,
  getGeoLocations,
  getGeoCities,
  getUpsells,
  getUpsellsCollections,
  getUpsellsCollectionById,
  putUpsells,
  getMerchantNote,
  putMerchantNote,
  getHTMLTemplate,
  addCustomLanguage,
  updateCustomLanguage,
  deleteCustomLanguage,
  getCountriesData,
  getTrackingPageLanguages,
  getPrivateLanguages,
  getLanguageData,
  getLanguageDataNew,
  createUploadFileChannel,
} = trackingWebServices;

export function* watchTrackingRequests() {
  yield takeEvery(Types.ADD_CUSTOM_LANGUAGE, requestAddCustomLanguage);
  yield takeEvery(Types.UPDATE_CUSTOM_LANGUAGE, requestUpdateCustomLanguage);
  yield takeEvery(Types.DELETE_CUSTOM_LANGUAGE, requestDeleteCustomLanguage);
  yield takeEvery(Types.GET_COUNTRIES_DATA, requestGetCountries);
  yield takeEvery(
    Types.GET_TRACKING_PAGE_LANGUAGES,
    requestGetTrackingPageLanguages
  );
  yield takeEvery(Types.GET_PRIVATE_LANGUAGES, requestGetPrivateLanguages);
  yield takeEvery(Types.GET_LANGUAGE_DATA, requestGetLanguage);
  yield takeEvery(
    Types.GET_LANGUAGE_DATA_FOR_UPDATE,
    requestGetLanguageDataForUpdate
  );

  yield takeEvery(Types.GET_TRACKING_PAGE_DETAIL, requestGetTrackingPageDetail);
  yield takeEvery(
    Types.SAVE_TRACKING_PAGE_DETAIL,
    requestSaveTrackingPageDetail
  );
  yield takeEvery(
    Types.SAVE_TRACKING_PAGE_BLACKLIST_GEO_LOC_REPLACE,
    requestSaveTrackingPageDetail
  );
  yield takeEvery(
    Types.SAVE_TRACKING_PAGE_BLACKLIST_GEO_LOC,
    requestSaveTrackingPageDetail
  );
  yield takeEvery(
    Types.SAVE_TRACKING_PAGE_BLACKLIST_FIND_REPLACE,
    requestSaveTrackingPageDetail
  );
  yield takeEvery(Types.UPLOAD_LOGO_REQUEST, requestUploadLogoRequest);
  yield takeEvery(Types.GET_GEO_LOCATIONS, requestGetGeoLocations);
  yield takeEvery(Types.GET_GEO_CITIES, requestGetGeoCities);
  yield takeEvery(Types.GET_UPSELLS, requestGetUpsells);
  yield takeEvery(Types.GET_UPSELLS_COLLECTIONS, requestGetUpsellsCollections);
  yield takeEvery(
    Types.GET_UPSELLS_COLLECTION_BY_ID,
    requestGetUpsellsCollectionById
  );
  yield takeEvery(Types.PUT_UPSELLS, requestPutUpsells);

  yield takeEvery(Types.GET_MERCHANT_NOTE, requestGetMerchantNote);
  yield takeEvery(Types.PUT_MERCHANT_NOTE, requestPutMerchantNote);
  yield takeEvery(Types.GET_HTML_TEMPLATE, requestGetHTMLTemplate);
}

function* requestGetTrackingPageDetail() {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(getTrackingPageDetail, accessToken, storeUuid);
    yield put(Creators.getTrackingPageDetailSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.getTrackingPageDetailFailure(error.data.errors));
    }
  }
}

function* requestSaveTrackingPageDetail(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);

    let params = { ...action.params };
    delete params.validationFields;
    delete params.upSellUpdated;
    delete params.upSellList;
    delete params.upSellCollections;
    delete params.upSellCollectionDetail;
    delete params.storeLogoError;
    delete params.meta;
    delete params.merchantNoteUpdated;
    delete params.merchantNoteList;
    delete params.loading;
    delete params.isUpdated;
    delete params.geoLocations;
    delete params.geoCities;
    delete params.dataLoaded;
    delete params.storeLogoProgress;
    delete params.storeLogoURL;
    delete params.trackingPageToaster;
    delete params.trackingSaved;
    delete params.templateHTML;
    delete params.locationsLoading;
    delete params.citiesLoading;
    delete params.customizeTheme;

    const response = yield call(
      saveTrackingPageDetail,
      accessToken,
      storeUuid,
      params
    );
    yield put(Creators.saveTrackingPageDetailSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.saveTrackingPageDetailFailure(error.data.errors));
    }
  }
}

function* requestGetGeoLocations(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const response = yield call(getGeoLocations, accessToken, action.params);
    yield put(Creators.getGeoLocationsSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.getGeoLocationsFailure(error.data.errors));
    }
  }
}

function* requestGetGeoCities(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const response = yield call(getGeoCities, accessToken, action.params);
    yield put(Creators.getGeoCitiesSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.getGeoCitiesFailure(error.data.errors));
    }
  }
}

function* requestUploadLogoRequest(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    yield call(uploadFileSaga, action.file[0], accessToken, storeUuid);
  } catch (error) {
    yield put(Creators.uploadLogoFailure(null, error.data.errors, 0));
  }
}

function* uploadFileSaga(file, accessToken, shopifyId) {
  const channel = yield call(
    createUploadFileChannel,
    `${API_URL}/stores/${shopifyId}/uploads`,
    accessToken,
    file,
    'logo'
  );
  while (true) {
    const { progress = 0, error, success, responseText } = yield take(channel);
    if (error) {
      yield put(Creators.uploadLogoFailure(file, error.data.errors, 0));
      return;
    }
    if (success) {
      yield put(Creators.uploadLogoSuccess(file, responseText, 100));
      return;
    }
    yield put(Creators.uploadProgress(file, progress));
  }
}

function* requestGetUpsells(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      getUpsells,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.getUpsellsSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.getUpsellsFailure(error.data.errors));
    }
  }
}

function* requestGetUpsellsCollections(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      getUpsellsCollections,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.getUpsellsCollectionsSuccess(response.data.data));
  } catch (error) {
    if (error) {
      yield put(Creators.getUpsellsCollectionsFailure(error.data.errors));
    }
  }
}

function* requestGetCountries() {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);

    const response = yield call(getCountriesData, accessToken);
    yield put(Creators.getCountriesDataSuccess(response.data));
  } catch (error) {
    yield put(Creators.getCountriesDataFailure(error.data.message));
  }
}

function* requestGetTrackingPageLanguages() {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);

    const response = yield call(getTrackingPageLanguages, accessToken);
    yield put(Creators.getTrackingPageLanguagesSuccess(response.data.data));
  } catch (error) {
    yield put(Creators.getTrackingPageLanguagesFailure(error.data.message));
  }
}

function* requestGetPrivateLanguages() {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);

    const response = yield call(getPrivateLanguages, accessToken, storeUuid);
    yield put(Creators.getPrivateLanguagesSuccess(response.data.data));
  } catch (error) {
    yield put(Creators.getPrivateLanguagesFailure(error.data.message));
  }
}

function* requestGetLanguage(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      getLanguageData,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.getLanguageDataSuccess(response.data));
  } catch (error) {
    yield put(Creators.getLanguageDataFailure(error.data.message));
  }
}

function* requestGetLanguageDataForUpdate(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);

    const response = yield call(getLanguageDataNew, accessToken, action.params);
    yield put(Creators.getLanguageDataForUpdateSuccess(response.data));
  } catch (error) {
    yield put(Creators.getLanguageDataForUpdateFailure(error.data.message));
  }
}

function* requestAddCustomLanguage(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);

    const response = yield call(
      addCustomLanguage,
      accessToken,
      storeUuid,
      action.params.data
    );
    if (response.status === 200) {
      action.params.callbackAction();
    }
    yield put(Creators.addCustomLanguageSuccess(response.data.data));
  } catch (error) {
    yield put(Creators.addCustomLanguageFailure(error.data.errors));
  }
}

function* requestUpdateCustomLanguage(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      updateCustomLanguage,
      accessToken,
      storeUuid,
      action.params.data,
      action.params.language_Id
    );
    if (response.status === 200) {
      action.params.callbackAction();
    }
    yield put(Creators.updateCustomLanguageSuccess(response.data.data));
  } catch (error) {
    yield put(Creators.updateCustomLanguageFailure(error.data.errors));
  }
}

function* requestDeleteCustomLanguage(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);

    const response = yield call(
      deleteCustomLanguage,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(
      Creators.deleteCustomLanguageSuccess(response.data, action.params)
    );
  } catch (error) {
    yield put(Creators.deleteCustomLanguageFailure(error?.data?.errors));
  }
}

function* requestGetUpsellsCollectionById(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      getUpsellsCollectionById,
      accessToken,
      storeUuid,
      action.collectionId
    );
    yield put(Creators.getUpsellsCollectionByIdSuccess(response.data.data));
  } catch (error) {
    if (error) {
      yield put(Creators.getUpsellsCollectionByIdFailure(error.data.errors));
    }
  }
}

function* requestPutUpsells(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      putUpsells,
      accessToken,
      storeUuid,
      action.id,
      action.params
    );
    yield put(Creators.putUpsellsSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.putUpsellsFailure(error.data.errors));
    }
  }
}

function* requestGetMerchantNote(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      getMerchantNote,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.getMerchantNoteSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.getMerchantNoteFailure(error.data.errors));
    }
  }
}

function* requestPutMerchantNote(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      putMerchantNote,
      accessToken,
      storeUuid,
      action.id,
      action.params
    );
    yield put(Creators.putMerchantNoteSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.putMerchantNoteFailure(error.data.errors));
    }
  }
}

function* requestGetHTMLTemplate(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);

    const response = yield call(
      getHTMLTemplate,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.getHTMLTemplateSuccess(response.data.data.template));
  } catch (error) {
    if (error) {
      yield put(Creators.getHTMLTemplateFailure(error.data.errors));
    }
  }
}
