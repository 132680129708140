import { connect } from 'react-redux';
import SaveBar from './SaveBar';

function mapStateToProps(state) {
  return {
    settingsLoading: state.settings.loading,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SaveBar);
