import { connect } from 'react-redux';
import { settingsActions } from 'redux/settings';
import KlaviyoPage from './KlaviyoPage';

function mapStateToProps(state) {
  return {
    loading: state.settings.loading,
    klaviyoConfig: state.settings.klaviyoConfig,
    klaviyoMeta: state.settings.klaviyoMeta,
    klaviyoTestingConfirmation: state.settings.klaviyoTestingConfirmation,
    klaviyoConfigSaved: state.settings.klaviyoConfigSaved,
    klaviyoFlowSummary: state.settings.klaviyoFlowSummary,
    klaviyoError: state.settings.klaviyoError,
    loadingFlow: state.settings.loadingFlow,
    loadingFlowError: state.settings.loadingFlowError,
    flowSummaryError: state.settings.flowSummaryError,
    loadingFeatureId: state.settings.loadingFeatureId,
    featureRequest: state.settings.featureRequest,
    shop: state.shop,
  };
}

const mapDispatchToProps = {
  activateFeature: settingsActions.Creators.activateFeature,
  deactivateFeature: settingsActions.Creators.deactivateFeature,
  getKlaviyo: settingsActions.Creators.getKlaviyo,
  saveKlaviyo: settingsActions.Creators.saveKlaviyo,
  testKlaviyo: settingsActions.Creators.testKlaviyo,
  sendAllEventsTestKlaviyo: settingsActions.Creators.sendAllEventsTestKlaviyo,
  resetKlaviyo: settingsActions.Creators.resetKlaviyo,
  getKlaviyoSummaryFlow: settingsActions.Creators.getKlaviyoSummaryFlow,
  resetFeatureRequest: settingsActions.Creators.resetFeatureRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(KlaviyoPage);
