import { connect } from 'react-redux';
import OmnisendActions from './OmnisendActions';
import { settingsActions } from '../../../../redux/settings';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    loading: state.settings.loading,
  };
}

const mapDispatchToProps = {
  testOmnisend: settingsActions.Creators.testOmnisend,
};

export default connect(mapStateToProps, mapDispatchToProps)(OmnisendActions);
