import { connect } from 'react-redux';
import { settingsActions } from 'redux/settings';
import SlackModal from './SlackModal';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    showSlackModal: state.settings.showSlackModal,
    loading: state.settings.loading,
    slackConfig: state.settings.slackConfig,
    slackMeta: state.settings.slackMeta,
    slackError: state.settings.slackError,
    slackTestingConfirmation: state.settings.slackTestingConfirmation,
    slackConfigSaved: state.settings.slackConfigSaved,
  };
}

const mapDispatchToProps = {
  handleShowSlackModal: settingsActions.Creators.handleShowSlackModal,
  getSlack: settingsActions.Creators.getSlack,
  saveSlack: settingsActions.Creators.saveSlack,
  testSlack: settingsActions.Creators.testSlack,
  resetSlack: settingsActions.Creators.resetSlack,
};

export default connect(mapStateToProps, mapDispatchToProps)(SlackModal);
