import { storeApi } from 'redux/store/storeApi';

export const shipbobAPIs = storeApi.injectEndpoints({
  endpoints: (builder) => ({
    getShipbob: builder.query({
      query: () => {
        return {
          url: `/shipbob`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      providesTags: ['shipbob'],
    }),
    updateShipbob: builder.mutation({
      query: (data) => {
        return {
          url: `/shipbob`,
          method: 'PATCH',
          body: { ...data },
        };
      },
      invalidatesTags: ['shipbob', 'settings'],
      transformResponse: (response = {}) => response?.data || response,
    }),
    deleteShipbob: builder.mutation({
      query: (id) => {
        return {
          url: `/shipbob/account/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['shipbob'],
    }),
  }),
});

export const {
  useGetShipbobQuery,
  useAddShipbobMutation,
  useUpdateShipbobMutation,
  useDeleteShipbobMutation,
} = shipbobAPIs;
