import { connect } from 'react-redux';
import { settingsActions } from 'redux/settings';
import KlaviyoPageCustomization from './KlaviyoPageCustomization';

function mapStateToProps(state) {
  return {
    shop: state.shop,
  };
}

const mapDispatchToProps = {
  activateFeature: settingsActions.Creators.activateFeature,
  deactivateFeature: settingsActions.Creators.deactivateFeature,
  resetKlaviyoConfig: settingsActions.Creators.resetKlaviyoConfig,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KlaviyoPageCustomization);
