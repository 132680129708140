import useIsEmbededApp from 'hooks/useIsEmbededApp';
import RushAuth from 'pages/ShopifyAuth/RushAuth';
import ShopifyLoginAuth from 'pages/ShopifyAuth/ShopifyLoginAuth';
import { AccessToken } from 'pages/Visitor/visitorSlice';
import React, { useEffect, useMemo } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import visitorRoutes from './VisitorRoutes';
import privateRoutes from './PrivateRoutes';
import { useSelector } from 'react-redux';
import RushExitIframe from 'pages/ShopifyAuth/RushExitIframe';
import { AUTH_URLS, VISITOR_URLS } from 'config/urls';
import { StoreUUID } from 'redux/store/shopSlice';
import { isEmbededAppExperience } from 'Utils/utils';
import RushApprovePage from 'pages/Permissions/components/RushApproval';
import useInstallRoute from 'hooks/useInstallRoute';
import { Banner } from '@shopify/polaris';

function AppRoutes({ isLoading }) {
  const location = useLocation();
  const navigate = useNavigate();
  const isEmbededApp = useIsEmbededApp();
  const accessToken = useSelector(AccessToken);
  const storeUUID = useSelector(StoreUUID);
  const isVerifiedApp = useMemo(
    () => !!(window.__SHOPIFY_DEV_IS_VERIFIED_APP || accessToken),
    [accessToken]
  );
  const installRoute = useInstallRoute();

  useEffect(() => {
    if (installRoute && accessToken) {
      navigate(installRoute);
    }
  }, [installRoute, accessToken]);

  useEffect(() => {
    if (location.pathname === '/') {
      if (isEmbededApp && !isEmbededAppExperience(storeUUID || '')) {
        navigate(`${AUTH_URLS.RUSH_SPLASH}`);
      } else if (storeUUID && storeUUID !== null) {
        navigate(`/s/${storeUUID}`, { replace: true });
      } else if (accessToken) {
        navigate(`/s`, { replace: true });
      } else {
        navigate(VISITOR_URLS.LOGIN, { replace: true });
      }
    }
  }, [location.pathname, storeUUID, isEmbededApp]);

  const banner = (
    <Banner
      title="Critical Update"
      tone="critical"
      action={{
        content: 'Book a Call',
        url: 'https://orderstatustracker.youcanbook.me/',
        target: '_blank',
        external: true,
      }}
    >
      <p>
        Starting Nov 25, the Rush app will no longer be available for
        installation or use. To avoid instabilities, we have made the decision
        to migrate our Rush merchants to the Hulk Order Status Tracker app. For
        next steps, please schedule a call with our Product Manager{' '}
      </p>
    </Banner>
  );

  if (isEmbededApp) {
    return (
      <>
        {banner}
        <Routes>
          <Route path={AUTH_URLS.RUSH_EXIT_IFRAME} Component={RushExitIframe} />
          <Route path={AUTH_URLS.RUSH_APPROVAL} Component={RushApprovePage} />
          <Route path={AUTH_URLS.SHOPIFY_AUTH} Component={ShopifyLoginAuth} />
          <Route path={AUTH_URLS.RUSH_AUTH} Component={RushAuth} />
          {isVerifiedApp && !isLoading ? (
            [...privateRoutes(storeUUID, accessToken)]
          ) : (
            <></>
          )}
        </Routes>
      </>
    );
  } else {
    return (
      !isLoading && (
        <>
          {banner}
          <Routes>
            {[
              ...visitorRoutes(accessToken),
              ...privateRoutes(storeUUID, accessToken),
            ]}
          </Routes>
        </>
      )
    );
  }
}

export default AppRoutes;
