module.exports = {
  dataLoaded: false,
  storeLogoProgress: 0,
  storeLogoURL: null,
  storeLogoError: null,
  geoLocations: [],
  geoCities: [],
  upSellList: [],
  upSellCollections: [],
  upSellCollectionDetail: null,
  upSellUpdated: null,
  merchantNoteList: [],
  merchantNoteUpdated: null,
  trackingPageToaster: {
    showToast: false,
    isErrorToast: null,
    toastText: null,
  },
  templateHTML: null,
  languages: null,
  privateLanguages: null,
  metaLanguages: null,
  metaCountries: null,
};
