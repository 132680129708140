import { connect } from 'react-redux';
import RealTimeStatusSection from './RealTimeStatusSection';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    orderStatusMeta: state.settings.orderStatusMeta,
  };
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RealTimeStatusSection);
