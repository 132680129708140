import { baseApi } from '../../redux/store/baseApi';

export const perksAPIs = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPerks: builder.query({
      query: () => {
        return {
          url: '/perks',
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
  }),
});

export const { useGetPerksQuery } = perksAPIs;
