const { API } = require('../../helpers/api');
const { API_URL } = require('../../config');

function getShopTasks(accessToken, shopifyId) {
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/${shopifyId}/tasks`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function completeShopTask(accessToken, shopifyId, taskSlug, requestBody) {
  return API.request({
    method: 'put',
    url: `${API_URL}/stores/${shopifyId}/tasks/${taskSlug}/complete`,
    data: requestBody,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getSettings(accessToken, shopifyId, params) {
  const endpoint = 'add-ons'; //params.type === 'service' ? 'add-ons' : 'settings';
  const urlParams = new URLSearchParams({ ...params }).toString();
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/${shopifyId}/${endpoint}?${urlParams}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function activateFeature(accessToken, shopifyId, featureId) {
  return API.request({
    method: 'PUT',
    url: `${API_URL}/stores/${shopifyId}/feature/${featureId}/activate`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function deactivateFeature(accessToken, shopifyId, featureId) {
  return API.request({
    method: 'PUT',
    url: `${API_URL}/stores/${shopifyId}/feature/${featureId}/deactivate`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getSlack(accessToken, shopifyId, params) {
  const urlParams = new URLSearchParams({ ...params }).toString();
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/${shopifyId}/notification/slack/config?${urlParams}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function saveSlack(accessToken, shopifyId, params) {
  return API.request({
    method: 'PUT',
    url: `${API_URL}/stores/${shopifyId}/notification/slack/config`,
    data: params,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function testSlack(accessToken, shopifyId, params) {
  return API.request({
    method: 'POST',
    url: `${API_URL}/stores/${shopifyId}/notification/slack/send_test`,
    data: params,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getKlaviyo(accessToken, shopifyId, params) {
  const urlParams = new URLSearchParams({ ...params }).toString();
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/${shopifyId}/notification/klaviyo/config?${urlParams}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function saveKlaviyo(accessToken, shopifyId, params) {
  return API.request({
    method: 'PUT',
    url: `${API_URL}/stores/${shopifyId}/notification/klaviyo/config`,
    data: params,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function testKlaviyo(accessToken, shopifyId, params) {
  return API.request({
    method: 'POST',
    url: `${API_URL}/stores/${shopifyId}/notification/klaviyo/send_test`,
    data: params,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function sendAllEventsTestKlaviyo(accessToken, shopifyId, params) {
  return API.request({
    method: 'POST',
    url: `${API_URL}/stores/${shopifyId}/notification/klaviyo/send-all-events-test`,
    data: params,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getOmnisend(accessToken, shopifyId, params) {
  const urlParams = new URLSearchParams({ ...params }).toString();
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/${shopifyId}/notification/omnisend/config?${urlParams}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function saveOmnisend(accessToken, shopifyId, params) {
  return API.request({
    method: 'PUT',
    url: `${API_URL}/stores/${shopifyId}/notification/omnisend/config`,
    data: params,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function testOmnisend(accessToken, shopifyId, params) {
  return API.request({
    method: 'POST',
    url: `${API_URL}/stores/${shopifyId}/notification/omnisend/send_test`,
    data: params,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

// Email functions.
function getEmailConfig(accessToken, shopifyId, params) {
  const urlParams = new URLSearchParams({ ...params }).toString();
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/${shopifyId}/notification/email_postmarkapp/config?${urlParams}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getEmailTriggersMeta(accessToken, shopifyId, params) {
  const urlParams = new URLSearchParams({ ...params }).toString();
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/${shopifyId}/notification/email_postmarkapp/triggers/meta?${urlParams}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function activateEmailTrigger(accessToken, shopifyId, triggerId) {
  return API.request({
    method: 'PUT',
    url: `${API_URL}/stores/${shopifyId}/notification/email_postmarkapp/triggers/${triggerId}/activate`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}
function deactivateEmailTrigger(accessToken, shopifyId, triggerId) {
  return API.request({
    method: 'PUT',
    url: `${API_URL}/stores/${shopifyId}/notification/email_postmarkapp/triggers/${triggerId}/deactivate`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getEmailTriggerById(accessToken, shopifyId, triggerId) {
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/${shopifyId}/notification/email_postmarkapp/triggers/${triggerId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function testEmailTriggerById(accessToken, shopifyId, params) {
  return API.request({
    method: 'POST',
    url: `${API_URL}/stores/${shopifyId}/notification/email_postmarkapp/triggers/send_test`,
    data: params,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function postEmailTrigger(accessToken, shopifyId, params) {
  return API.request({
    method: 'POST',
    url: `${API_URL}/stores/${shopifyId}/notification/email_postmarkapp/triggers`,
    data: params,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function putEmailTriggerById(accessToken, shopifyId, triggerId, params) {
  return API.request({
    method: 'PUT',
    url: `${API_URL}/stores/${shopifyId}/notification/email_postmarkapp/triggers/${triggerId}`,
    data: params,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function updateEmailSender(accessToken, shopifyId, params) {
  return API.request({
    method: 'PUT',
    url: `${API_URL}/stores/${shopifyId}/notification/email_postmarkapp/sender`,
    data: params,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function deleteEmailTriggerById(accessToken, shopifyId, triggerId) {
  return API.request({
    method: 'DELETE',
    url: `${API_URL}/stores/${shopifyId}/notification/email_postmarkapp/triggers/${triggerId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getEmailTemplatePreview(accessToken, shopifyId, templateId) {
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/${shopifyId}/notification/email_postmarkapp/email-preview?template=${templateId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getDashboardTasks(accessToken, shopifyId) {
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/${shopifyId}/tasks/dashboard`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function dismissDashboardTasks(accessToken, shopifyId, taskId) {
  return API.request({
    method: 'PUT',
    url: `${API_URL}/stores/${shopifyId}/tasks/dashboard/dismiss/${taskId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function completeDashboardTasks(accessToken, shopifyId, taskId) {
  return API.request({
    method: 'PUT',
    url: `${API_URL}/stores/${shopifyId}/tasks/dashboard/complete/${taskId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getPaypalConfig(accessToken, shopifyId) {
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/${shopifyId}/notification/paypal/config`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function putPaypalConfig(accessToken, shopifyId, params) {
  return API.request({
    method: 'PUT',
    url: `${API_URL}/stores/${shopifyId}/notification/paypal/config`,
    data: params,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function postPaypalAccount(accessToken, shopifyId, params) {
  return API.request({
    method: 'POST',
    url: `${API_URL}/stores/${shopifyId}/paypal/account`,
    data: params,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function putPaypalAccount(accessToken, shopifyId, accountId, params) {
  const urlParams = new URLSearchParams({ ...params }).toString();
  return API.request({
    method: 'PUT',
    url: `${API_URL}/stores/${shopifyId}/paypal/account/${accountId}?${urlParams}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function deletePaypalAccount(accessToken, shopifyId, accountId) {
  return API.request({
    method: 'DELETE',
    url: `${API_URL}/stores/${shopifyId}/paypal/account/${accountId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getPaypalTrackings(accessToken, shopifyId, params) {
  const urlParams = new URLSearchParams({ ...params }).toString();
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/${shopifyId}/notification/paypal/trackings/?${urlParams}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function exportPaypalTrackings(accessToken, shopifyId, params) {
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/${shopifyId}/notification/paypal/trackings/export`,
    data: params,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getShipBobConfig(accessToken, shopifyId) {
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/${shopifyId}/shipbob`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function deleteShipBobAccount(accessToken, shopifyId, accountId) {
  return API.request({
    method: 'DELETE',
    url: `${API_URL}/stores/${shopifyId}/shipbob/account/${accountId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getOrderStatusPageConfigurations(accessToken, shopifyId) {
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/${shopifyId}/order-status-page-configuration`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function putOrderStatusPageConfigurations(accessToken, shopifyId, requestBody) {
  return API.request({
    method: 'put',
    url: `${API_URL}/stores/${shopifyId}/order-status-page-configuration`,
    data: requestBody,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getKlaviyoSummaryFlow(accessToken, shopifyId, private_api_key) {
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/${shopifyId}/notification/klaviyo/flows?private_api_key=${private_api_key}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getOrderStatusPagePreviewLink(accessToken, shopifyId) {
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/${shopifyId}/order-status-page-configuration/preview?is_shipments=1`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

module.exports = {
  getShopTasks,
  completeShopTask,
  getSettings,
  activateFeature,
  deactivateFeature,
  getSlack,
  saveSlack,
  testSlack,
  getKlaviyo,
  saveKlaviyo,
  testKlaviyo,
  getOmnisend,
  saveOmnisend,
  testOmnisend,
  getEmailConfig,
  activateEmailTrigger,
  deactivateEmailTrigger,
  testEmailTriggerById,
  deleteEmailTriggerById,
  getEmailTriggersMeta,
  getEmailTemplatePreview,
  postEmailTrigger,
  getEmailTriggerById,
  putEmailTriggerById,
  sendAllEventsTestKlaviyo,
  getDashboardTasks,
  dismissDashboardTasks,
  completeDashboardTasks,
  updateEmailSender,
  putPaypalConfig,
  getPaypalTrackings,
  exportPaypalTrackings,
  getPaypalConfig,
  postPaypalAccount,
  putPaypalAccount,
  deletePaypalAccount,
  getShipBobConfig,
  deleteShipBobAccount,
  getOrderStatusPageConfigurations,
  putOrderStatusPageConfigurations,
  getKlaviyoSummaryFlow,
  getOrderStatusPagePreviewLink,
};
