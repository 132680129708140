import { connect } from 'react-redux';
import { trackingActions } from 'redux/tracking';
import { settingsActions } from 'redux/settings';
import LanguagePage from './LanguagePage';

function mapStateToProps(state) {
  return {
    loading: state.trackingDetail.loading,
    languages: state.trackingDetail.languages,
    countriesList: state.trackingDetail.metaCountries,
    publicLanguagesList: state.trackingDetail.metaLanguages,
    languageData: state.trackingDetail.customLanguageData,
    languageDataForUpdate: state.trackingDetail.languageDataForUpdate,
    shop: state.shop,
  };
}

const mapDispatchToProps = {
  addCustomLanguage: trackingActions.Creators.addCustomLanguage,
  updateCustomLanguage: trackingActions.Creators.updateCustomLanguage,
  getTrackingPageLanguages: trackingActions.Creators.getTrackingPageLanguages,
  getCountriesData: trackingActions.Creators.getCountriesData,
  getLanguageDataForUpdate: trackingActions.Creators.getLanguageDataForUpdate,
  showUnsavedModal: settingsActions.Creators.showUnsavedModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguagePage);
