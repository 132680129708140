import { storeApi } from '../../redux/store/storeApi';

export const maskingAPIs = storeApi.injectEndpoints({
  endpoints: (builder) => ({
    getCarrierMasking: builder.query({
      query: () => {
        return {
          url: `/carrier-masking`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      providesTags: ['carrierMasking'],
    }),
    addCarrierMasking: builder.mutation({
      query: (data) => {
        return {
          url: '/carrier-masking',
          method: 'POST',
          body: { ...data },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      invalidatesTags: ['carrierMasking'],
    }),
    updateCarrierMasking: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/carrier-masking/${id}`,
          method: 'PATCH',
          body: { ...data },
        };
      },
      invalidatesTags: ['carrierMasking'],
      transformResponse: (response = {}) => response?.data || response,
    }),
    deleteCarrierMasking: builder.mutation({
      query: (id) => {
        return {
          url: `/carrier-masking/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['carrierMasking'],
    }),
    uploadLogo: builder.mutation({
      query: (file) => {
        const logoFile = new FormData();
        logoFile.append('file', file);
        logoFile.append('path', 'carrier-masking-logo');
        return {
          url: `/uploads`,
          method: 'POST',
          body: logoFile,
        };
      },
    }),
  }),
});

export const {
  useGetCarrierMaskingQuery,
  useAddCarrierMaskingMutation,
  useUpdateCarrierMaskingMutation,
  useDeleteCarrierMaskingMutation,
  useUploadLogoMutation,
} = maskingAPIs;
