const {
  call,
  put,
  takeEvery,
  takeLatest,
  select,
} = require('redux-saga/effects');
const settingsActions = require('./settings.actions');
const settingsWebServices = require('./settings.webServices');
const i18n = require('i18n').default;

const { Types, Creators } = settingsActions;
const {
  getShopTasks,
  completeShopTask,
  getSettings,
  activateFeature,
  deactivateFeature,
  getSlack,
  saveSlack,
  testSlack,
  getKlaviyo,
  saveKlaviyo,
  testKlaviyo,
  getOmnisend,
  saveOmnisend,
  testOmnisend,
  getEmailConfig,
  activateEmailTrigger,
  deactivateEmailTrigger,
  testEmailTriggerById,
  deleteEmailTriggerById,
  getEmailTriggersMeta,
  getEmailTemplatePreview,
  postEmailTrigger,
  getEmailTriggerById,
  putEmailTriggerById,
  sendAllEventsTestKlaviyo,
  getDashboardTasks,
  dismissDashboardTasks,
  completeDashboardTasks,
  updateEmailSender,
  putPaypalConfig,
  getPaypalTrackings,
  exportPaypalTrackings,
  getPaypalConfig,
  postPaypalAccount,
  putPaypalAccount,
  deletePaypalAccount,
  getShipBobConfig,
  deleteShipBobAccount,
  getOrderStatusPageConfigurations,
  putOrderStatusPageConfigurations,
  getKlaviyoSummaryFlow,
  getOrderStatusPagePreviewLink,
} = settingsWebServices;

export function* watchSettingsRequests() {
  yield takeEvery(Types.GET_SHOP_TASKS, requestGetShopTasks);
  yield takeEvery(Types.COMPLETE_SHOP_TASK, requestCompleteShopTask);
  yield takeEvery(Types.GET_SETTINGS, requestGetSettings);
  yield takeEvery(Types.ACTIVATE_FEATURE, requestActivateFeature);
  yield takeEvery(Types.DEACTIVATE_FEATURE, requestDeactivateFeature);
  // SLACK
  yield takeEvery(Types.GET_SLACK, requestGetSlack);
  yield takeEvery(Types.SAVE_SLACK, requestSaveSlack);
  yield takeEvery(Types.TEST_SLACK, requestTestSlack);
  // KLAVIYO
  yield takeLatest(Types.GET_KLAVIYO, requestGetKlaviyo);
  yield takeEvery(Types.SAVE_KLAVIYO, requestSaveKlaviyo);
  yield takeEvery(Types.TEST_KLAVIYO, requestTestKlaviyo);
  yield takeEvery(
    Types.SEND_ALL_EVENTS_TEST_KLAVIYO,
    requestSendAllEventsTestKlaviyo
  );
  // Omnisend
  yield takeEvery(Types.GET_OMNISEND, requestGetOmnisend);
  yield takeEvery(Types.SAVE_OMNISEND, requestSaveOmnisend);
  yield takeEvery(Types.TEST_OMNISEND, requestTestOmnisend);
  // EMAILS
  yield takeEvery(Types.GET_EMAIL_CONFIG, requestGetEmailConfig);
  yield takeEvery(Types.ACTIVATE_EMAIL_TRIGGER, requestActivateEmailTrigger);
  yield takeEvery(
    Types.DEACTIVATE_EMAIL_TRIGGER,
    requestDeactivateEmailTrigger
  );
  yield takeEvery(Types.TEST_EMAIL_TRIGGER_BY_ID, requestTestEmailTriggerById);
  yield takeEvery(
    Types.DELETE_EMAIL_TRIGGER_BY_ID,
    requestDeleteEmailTriggerById
  );
  yield takeEvery(Types.GET_EMAIL_TRIGGERS_META, requestGetEmailTriggersMeta);
  yield takeEvery(
    Types.GET_EMAIL_TEMPLATE_PREVIEW,
    requestGetEmailTemplatePreview
  );
  yield takeEvery(Types.POST_EMAIL_TRIGGER, requestPostEmailTrigger);
  yield takeEvery(Types.GET_EMAIL_TRIGGER_BY_ID, requestGetEmailTriggerById);
  yield takeEvery(Types.PUT_EMAIL_TRIGGER_BY_ID, requestPutEmailTriggerById);
  // dashboard tasks
  yield takeEvery(Types.GET_DASHBOARD_TASKS, requestGetDashboardTasks);
  yield takeEvery(Types.DISMISS_DASHBOARD_TASKS, requestDismissDashboardTasks);
  yield takeEvery(
    Types.COMPLETE_DASHBOARD_TASKS,
    requestCompleteDashboardTasks
  );
  yield takeEvery(Types.UPDATE_EMAIL_SENDER, requestUpdateEmailSender);
  // paypal settings.
  yield takeEvery(Types.GET_PAYPAL_CONFIG, requestGetPaypalConfig);
  yield takeEvery(Types.PUT_PAYPAL_CONFIG, requestPutPaypalConfig);
  yield takeEvery(Types.POST_PAYPAL_ACCOUNT, requestPostPaypalAccount);
  yield takeEvery(Types.PUT_PAYPAL_ACCOUNT, requestPutPaypalAccount);
  yield takeEvery(Types.DELETE_PAYPAL_ACCOUNT, requestDeletePaypalAccount);
  yield takeEvery(Types.GET_PAYPAL_TRACKINGS, requestGetPaypalTrackings);
  yield takeEvery(Types.EXPORT_PAYPAL_TRACKINGS, requestExportPaypalTrackings);
  // shipbob
  yield takeEvery(Types.GET_SHIP_BOB_CONFIG, requestGetShipbobConfig);
  yield takeEvery(Types.DELETE_SHIP_BOB_ACCOUNT, requestDeleteShipBobAccount);

  // ThankyouPage
  yield takeEvery(
    Types.GET_ORDER_STATUS_PAGE_CONFIGURATIONS,
    requestGetOrderStatusPageConfigurations
  );
  yield takeEvery(
    Types.PUT_ORDER_STATUS_PAGE_CONFIGURATIONS,
    requestPutOrderStatusPageConfigurations
  );

  // Klaviyo Summary Flow
  yield takeLatest(
    Types.GET_KLAVIYO_SUMMARY_FLOW,
    requestGetKlaviyoSummaryFlow
  );

  yield takeLatest(
    Types.GET_ORDER_STATUS_PAGE_PREVIEW_LINK,
    requestGetOrderStatusPagePreviewLink
  );
}

export function* requestGetShopTasks(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    // LANGUAGE ENABLED THROUGH PARAMS.
    const response = yield call(
      getShopTasks,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.getShopTasksSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.getShopTasksFailure(error.data.errors));
    }
  }
}

function* requestCompleteShopTask(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      completeShopTask,
      accessToken,
      storeUuid,
      action.params.taskId,
      action.params.params
    );
    yield put(
      Creators.completeShopTaskSuccess(response.data, action.params.taskId)
    );
  } catch (error) {
    if (error) {
      yield put(Creators.completeShopTaskFailure(error.data.errors));
    }
  }
}

function* requestGetSettings(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      getSettings,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.getSettingsSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.getSettingsFailure(error.data.errors));
    }
  }
}

function* requestActivateFeature(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      activateFeature,
      accessToken,
      storeUuid,
      action.featureId
    );
    yield put(
      Creators.activateFeatureSuccess(response.data, action.flag || '')
    );
  } catch (error) {
    if (error) {
      yield put(Creators.activateFeatureFailure(error.data.errors));
    }
  }
}

function* requestDeactivateFeature(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      deactivateFeature,
      accessToken,
      storeUuid,
      action.featureId
    );
    yield put(
      Creators.deactivateFeatureSuccess(response.data, action.flag || '')
    );
  } catch (error) {
    if (error) {
      yield put(Creators.deactivateFeatureFailure(error.data.errors));
    }
  }
}

function* requestGetSlack(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      getSlack,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.getSlackSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.getSlackFailure(error.data.errors));
    }
  }
}
function* requestSaveSlack(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      saveSlack,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.saveSlackSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.saveSlackFailure(error.data.errors));
    }
  }
}
function* requestTestSlack(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      testSlack,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.testSlackSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.testSlackFailure(error.data.errors));
    }
  }
}

function* requestGetKlaviyo(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      getKlaviyo,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.getKlaviyoSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.getKlaviyoFailure(error.data.errors));
    }
  }
}
function* requestSaveKlaviyo(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      saveKlaviyo,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.saveKlaviyoSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.saveKlaviyoFailure(error.data.errors));
    }
  }
}
function* requestTestKlaviyo(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      testKlaviyo,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.testKlaviyoSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(
        Creators.testKlaviyoFailure({
          message: i18n.t('klaviyo.modal.test_failed'),
        })
      );
    }
  }
}
function* requestSendAllEventsTestKlaviyo(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      sendAllEventsTestKlaviyo,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(
      Creators.sendAllEventsTestKlaviyoSuccess(response.data.data.message)
    );
  } catch (error) {
    if (error) {
      yield put(Creators.sendAllEventsTestKlaviyoFailure(error.data.errors));
    }
  }
}
function* requestGetOmnisend(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      getOmnisend,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.getOmnisendSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.getOmnisendFailure(error.data.errors));
    }
  }
}
function* requestSaveOmnisend(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      saveOmnisend,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.saveOmnisendSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.saveOmnisendFailure(error.data.errors));
    }
  }
}
function* requestTestOmnisend(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      testOmnisend,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.testOmnisendSuccess(response.data.data.message));
  } catch (error) {
    yield put(
      Creators.testOmnisendFailure(
        error?.data?.errors || { message: i18n.t('omnisend.modal.test_failed') }
      )
    );
  }
}

function* requestGetEmailConfig(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      getEmailConfig,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.getEmailConfigSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.getEmailConfigFailure(error.data.errors));
    }
  }
}

function* requestActivateEmailTrigger(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      activateEmailTrigger,
      accessToken,
      storeUuid,
      action.triggerId
    );
    yield put(Creators.activateEmailTriggerSuccess(response.data.data.trigger));
  } catch (error) {
    if (error) {
      yield put(Creators.activateEmailTriggerFailure(error.data.errors));
    }
  }
}

function* requestDeactivateEmailTrigger(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      deactivateEmailTrigger,
      accessToken,
      storeUuid,
      action.triggerId
    );
    yield put(
      Creators.deactivateEmailTriggerSuccess(response.data.data.trigger)
    );
  } catch (error) {
    if (error) {
      yield put(Creators.deactivateEmailTriggerFailure(error.data.errors));
    }
  }
}

function* requestTestEmailTriggerById(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      testEmailTriggerById,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.testEmailTriggerByIdSuccess(response.data.data.message));
  } catch (error) {
    if (error) {
      yield put(Creators.testEmailTriggerByIdFailure(error.data.errors));
    }
  }
}

function* requestDeleteEmailTriggerById(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    yield call(
      deleteEmailTriggerById,
      accessToken,
      storeUuid,
      action.triggerId
    );
    yield put(Creators.deleteEmailTriggerByIdSuccess(action.triggerId));
  } catch (error) {
    if (error) {
      yield put(Creators.deleteEmailTriggerByIdFailure(error.data.errors));
    }
  }
}
function* requestGetEmailTriggerById(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      getEmailTriggerById,
      accessToken,
      storeUuid,
      action.triggerId
    );
    yield put(Creators.getEmailTriggerByIdSuccess(response.data.data.config));
  } catch (error) {
    if (error) {
      yield put(Creators.getEmailTriggerByIdFailure(error.data.errors));
    }
  }
}

function* requestGetEmailTriggersMeta(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      getEmailTriggersMeta,
      accessToken,
      storeUuid,
      action.triggerId
    );
    yield put(Creators.getEmailTriggersMetaSuccess(response.data.data.meta));
  } catch (error) {
    if (error) {
      yield put(Creators.getEmailTriggersMetaFailure(error.data.errors));
    }
  }
}
function* requestGetEmailTemplatePreview(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      getEmailTemplatePreview,
      accessToken,
      storeUuid,
      action.templateId
    );
    yield put(Creators.getEmailTemplatePreviewSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.getEmailTemplatePreviewFailure(error.data.errors));
    }
  }
}

function* requestPostEmailTrigger(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      postEmailTrigger,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.postEmailTriggerSuccess(response.data.data.config));
  } catch (error) {
    if (error) {
      yield put(Creators.postEmailTriggerFailure(error));
    }
  }
}

function* requestPutEmailTriggerById(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      putEmailTriggerById,
      accessToken,
      storeUuid,
      action.triggerId,
      action.params
    );
    yield put(Creators.putEmailTriggerByIdSuccess(response.data.data.config));
  } catch (error) {
    if (error) {
      yield put(Creators.putEmailTriggerByIdFailure(error));
    }
  }
}
function* requestUpdateEmailSender(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      updateEmailSender,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.updateEmailSenderSuccess(response.data.data.config));
  } catch (error) {
    if (error) {
      yield put(Creators.updateEmailSenderFailure(error));
    }
  }
}
function* requestGetDashboardTasks(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    // LANGUAGE ENABLED THROUGH PARAMS.
    const response = yield call(
      getDashboardTasks,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.getDashboardTasksSuccess(response.data.data.tasks));
  } catch (error) {
    if (error) {
      yield put(Creators.getDashboardTasksFailure(error));
    }
  }
}
function* requestDismissDashboardTasks(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      dismissDashboardTasks,
      accessToken,
      storeUuid,
      action.taskId
    );
    yield put(
      Creators.dismissDashboardTasksSuccess(response.data.data.message)
    );
  } catch (error) {
    if (error) {
      yield put(Creators.dismissDashboardTasksFailure(error));
    }
  }
}
function* requestCompleteDashboardTasks(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      completeDashboardTasks,
      accessToken,
      storeUuid,
      action.taskId
    );
    yield put(
      Creators.completeDashboardTasksSuccess(response.data.data.message)
    );
  } catch (error) {
    if (error) {
      yield put(Creators.completeDashboardTasksFailure(error));
    }
  }
}
function* requestGetPaypalConfig() {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(getPaypalConfig, accessToken, storeUuid);
    yield put(Creators.getPaypalConfigSuccess(response.data.data));
  } catch (error) {
    if (error) {
      yield put(Creators.getPaypalConfigFailure(error.data.errors));
    }
  }
}
function* requestPutPaypalConfig(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      putPaypalConfig,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.putPaypalConfigSuccess(response.data.data));
  } catch (error) {
    if (error) {
      yield put(Creators.putPaypalConfigFailure(error));
    }
  }
}
function* requestPostPaypalAccount(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      postPaypalAccount,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.postPaypalAccountSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.postPaypalAccountFailure(error));
    }
  }
}

function* requestPutPaypalAccount(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      putPaypalAccount,
      accessToken,
      storeUuid,
      action.accountId,
      action.params
    );
    yield put(Creators.putPaypalAccountSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.putPaypalAccountFailure(error));
    }
  }
}
function* requestDeletePaypalAccount(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      deletePaypalAccount,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.deletePaypalAccountSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.deletePaypalAccountFailure(error));
    }
  }
}

function* requestGetPaypalTrackings(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      getPaypalTrackings,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.getPaypalTrackingsSuccess(response.data.data));
  } catch (error) {
    if (error) {
      yield put(Creators.getPaypalTrackingsFailure(error));
    }
  }
}
function* requestExportPaypalTrackings(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      exportPaypalTrackings,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.exportPaypalTrackingsSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.exportPaypalTrackingsFailure(error));
    }
  }
}

function* requestGetShipbobConfig() {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(getShipBobConfig, accessToken, storeUuid);
    yield put(Creators.getShipBobConfigSuccess(response.data.data));
  } catch (error) {
    if (error) {
      yield put(Creators.getShipBobConfigFailure(error));
    }
  }
}
function* requestDeleteShipBobAccount(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      deleteShipBobAccount,
      accessToken,
      storeUuid,
      action.params.accountId
    );
    yield put(Creators.deleteShipBobAccountSuccess(response.data.data));
  } catch (error) {
    if (error) {
      yield put(Creators.deleteShipBobAccountFailure(error));
    }
  }
}

function* requestGetOrderStatusPageConfigurations() {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      getOrderStatusPageConfigurations,
      accessToken,
      storeUuid
    );
    yield put(
      Creators.getOrderStatusPageConfigurationsSuccess(response.data.data)
    );
  } catch (error) {
    if (error) {
      yield put(Creators.getOrderStatusPageConfigurationsFailure(error));
    }
  }
}

function* requestGetKlaviyoSummaryFlow(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const private_api_key = action.payload;
    const response = yield call(
      getKlaviyoSummaryFlow,
      accessToken,
      storeUuid,
      private_api_key
    );
    yield put(Creators.getKlaviyoSummaryFlowSuccess(response.data.data));
  } catch (error) {
    if (error) {
      yield put(Creators.getKlaviyoSummaryFlowFailure(error.data.errors));
    }
  }
}

function* requestPutOrderStatusPageConfigurations(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      putOrderStatusPageConfigurations,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(
      Creators.putOrderStatusPageConfigurationsSuccess(response.data.data)
    );
  } catch (error) {
    if (error) {
      yield put(Creators.putOrderStatusPageConfigurationsFailure(error));
    }
  }
}

function* requestGetOrderStatusPagePreviewLink() {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      getOrderStatusPagePreviewLink,
      accessToken,
      storeUuid
    );
    yield put(
      Creators.getOrderStatusPagePreviewLinkSuccess(response.data.data)
    );
  } catch (error) {
    if (error) {
      yield put(
        Creators.getOrderStatusPagePreviewLinkFailure(error.data.errors)
      );
    }
  }
}
