import { connect } from 'react-redux';
import { trackingActions } from 'redux/tracking';
import Upsells from './Upsells';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    upSellList: state.trackingDetail.upSellList,
    upSellCollections: state.trackingDetail.upSellCollections.map(
      (collection) => ({ value: collection.id, label: collection.label })
    ),
    upSellUpdated: state.trackingDetail.upSellUpdated,
    upSellLoading: state.trackingDetail.upSellLoading,
  };
}

const mapDispatchToProps = {
  getUpsells: trackingActions.Creators.getUpsells,
  getUpsellsCollections: trackingActions.Creators.getUpsellsCollections,
  putUpsells: trackingActions.Creators.putUpsells,
};

export default connect(mapStateToProps, mapDispatchToProps)(Upsells);
