import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showUpdateModal: false,
  showRedeemModal: false,
  showChangeLimitModal: false,
};

const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    toggleUpdateModal(state, action) {
      if (action.payload !== undefined) {
        state.showUpdateModal = action.payload;
      } else {
        state.showUpdateModal = !state.showUpdateModal;
      }
    },
    toggleRedeemModal(state) {
      state.showRedeemModal = !state.showRedeemModal;
    },
    toggleChargeLimitModal(state) {
      state.showChangeLimitModal = !state.showChangeLimitModal;
    },
  },
});

// Actions
export const billingActions = billingSlice.actions;

// Reducer
const billingReducer = billingSlice.reducer;
export default billingReducer;
