const initialState = require('./settings.initialState');
const settingsActions = require('./settings.actions');
const settingsReducer = require('./settings.reducer');
const settingsWebServices = require('./settings.webServices');
const { watchSettingsRequests } = require('./settings.sagas.js');

module.exports = {
  initialState,
  settingsActions,
  settingsReducer,
  settingsWebServices,
  watchSettingsRequests,
}
