export const validateForm = ({ formValidations, rule }) => {
  let isValid = true;
  let validations = { ...formValidations };
  if (!rule.target_carriers_all && !rule.target_carriers_uuid.length) {
    validations.override_carrier = false;
    isValid = false;
  } else {
    validations.override_carrier = true;
  }
  if (
    !rule.completely_hide_carrier &&
    rule.override_name_option === 2 &&
    !rule.override_name_value
  ) {
    validations.override_name = false;
    isValid = false;
  } else {
    validations.override_name = true;
  }
  if (
    !rule.completely_hide_carrier &&
    rule.override_logo_option === 2 &&
    !rule.override_logo_value
  ) {
    validations.override_logo = false;
    isValid = false;
  } else {
    validations.override_logo = true;
  }
  if (
    !rule.completely_hide_carrier &&
    rule.override_website_option === 2 &&
    !rule.override_website_value
  ) {
    validations.override_website = false;
    isValid = false;
  } else {
    validations.override_website = true;
  }
  if (
    !rule.completely_hide_carrier &&
    rule.override_tracking_link_option === 2 &&
    !rule.override_tracking_link_value
  ) {
    validations.override_tracking_link = false;
    isValid = false;
  } else {
    validations.override_tracking_link = true;
  }
  if (
    !rule.completely_hide_carrier &&
    rule.override_phone_option === 2 &&
    !rule.override_phone_value
  ) {
    validations.override_phone = false;
    isValid = false;
  } else {
    validations.override_phone = true;
  }
  return {
    isValid,
    validations,
  };
};

export const getTargetCarrierLabel = (uuids, allCarriers) => {
  return uuids
    .map((uuid) => {
      return (allCarriers.find((c) => c.uuid === uuid) || {}).title || '';
    })
    .join(', ');
};
