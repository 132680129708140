import React from 'react';
import { EmptyState } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';

function PreShipmentEmptyState() {
  const [t] = useTranslation();

  return (
    <EmptyState
      heading={t('pre-shipment.no-pre-shipments-found')}
      image="https://assets.rush.app/app/pre-shipment/pre-shipments-no-items.png"
    ></EmptyState>
  );
}

export default PreShipmentEmptyState;
