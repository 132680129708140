const { createActions } = require('reduxsauce');

module.exports = createActions({
  resetTrackingPageToaster: null,
  showTrackingPageToaster: ['params'],

  getCountriesData: ['params'],
  getCountriesDataSuccess: ['detail'],
  getCountriesDataFailure: ['error'],

  getLanguageData: ['params'],
  getLanguageDataSuccess: ['detail'],
  getLanguageDataFailure: ['error'],

  resetLanguageDataForUpdate: null,
  getLanguageDataForUpdate: ['params'],
  getLanguageDataForUpdateSuccess: ['detail'],
  getLanguageDataForUpdateFailure: ['error'],

  deleteCustomLanguage: ['params'],
  deleteCustomLanguageSuccess: ['response', 'uuid', 'default_language'],
  deleteCustomLanguageFailure: ['error'],

  addCustomLanguage: ['params'],
  addCustomLanguageSuccess: ['detail'],
  addCustomLanguageFailure: ['error'],

  updateCustomLanguage: ['params'],
  updateCustomLanguageSuccess: ['detail'],
  updateCustomLanguageFailure: ['error'],

  getTrackingPageDetail: ['params'],
  getTrackingPageDetailSuccess: ['detail'],
  getTrackingPageDetailFailure: ['error'],

  saveTrackingPageDetail: ['params'],
  saveTrackingPageBlacklistGeoLoc: ['params'],
  saveTrackingPageBlacklistGeoLocReplace: ['params'],
  saveTrackingPageBlacklistFindReplace: ['params'],
  saveTrackingPageDetailSuccess: ['detail'],
  saveTrackingPageDetailFailure: ['error'],

  uploadLogoRequest: ['file'],
  uploadLogoProgress: ['file', 'progress'],
  uploadLogoSuccess: ['file', 'responseText', 'progress'],
  uploadLogoFailure: ['file', 'error', 'progress'],
  uploadLogoReset: null,

  getGeoLocations: ['params'],
  getGeoLocationsSuccess: ['list'],
  getGeoLocationsFailure: ['error'],

  getGeoCities: ['params'],
  getGeoCitiesSuccess: ['list'],
  getGeoCitiesFailure: ['error'],

  getUpsells: ['params'],
  getUpsellsSuccess: ['list'],
  getUpsellsFailure: ['error'],

  getUpsellsCollections: ['params'],
  getUpsellsCollectionsSuccess: ['list'],
  getUpsellsCollectionsFailure: ['error'],

  getUpsellsCollectionById: ['collectionId'],
  getUpsellsCollectionByIdSuccess: ['collection'],
  getUpsellsCollectionByIdFailure: ['error'],

  resetUpsells: ['params'],
  putUpsells: ['id', 'params'],
  putUpsellsSuccess: ['list'],
  putUpsellsFailure: ['error'],

  getMerchantNote: ['params'],
  getMerchantNoteSuccess: ['list'],
  getMerchantNoteFailure: ['error'],

  putMerchantNote: ['id', 'params'],
  putMerchantNoteSuccess: ['list'],
  putMerchantNoteFailure: ['error'],

  getHTMLTemplate: ['params'],
  getHTMLTemplateSuccess: ['template'],
  getHTMLTemplateFailure: ['error'],

  getTrackingPageLanguages: null,
  getTrackingPageLanguagesSuccess: ['data'],
  getTrackingPageLanguagesFailure: ['error'],

  getPrivateLanguages: null,
  getPrivateLanguagesSuccess: ['data'],
  getPrivateLanguagesFailure: ['error'],
});
