import { storeApi } from '../../redux/store/storeApi';

export const maskingAPIs = storeApi.injectEndpoints({
  endpoints: (builder) => ({
    getBlacklist: builder.query({
      query: () => {
        return {
          url: `/blacklist`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      providesTags: ['blackListing'],
    }),
    getGeoLocations: builder.query({
      query: () => {
        return {
          url: `/blacklist/geo-locations`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getGeoCities: builder.query({
      query: (search = 'ok') => {
        return {
          url: `/blacklist/search-location?input=${search}`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    updateBlackListing: builder.mutation({
      query: (data) => {
        return {
          url: `/blacklist`,
          method: 'PUT',
          body: { ...data },
        };
      },
      invalidatesTags: ['blackListing'],
      transformResponse: (response = {}) => response?.data || response,
    }),
  }),
});

export const {
  useGetBlacklistQuery,
  useGetGeoLocationsQuery,
  useLazyGetGeoCitiesQuery,
  useUpdateBlackListingMutation,
} = maskingAPIs;
