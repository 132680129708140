import { connect } from 'react-redux';
import { trackingActions } from 'redux/tracking';
import FeatureSection from './FeatureSection';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    upSellCollections: state.trackingDetail.upSellCollections.map(
      (collection) => ({ value: collection.id, label: collection.label })
    ),
    upSellCollectionDetail: state.trackingDetail.upSellCollectionDetail,
  };
}

const mapDispatchToProps = {
  getUpsellsCollections: trackingActions.Creators.getUpsellsCollections,
  getUpsellsCollectionById: trackingActions.Creators.getUpsellsCollectionById,
};

export default connect(mapStateToProps, mapDispatchToProps)(FeatureSection);
