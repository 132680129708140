const initialState = require('./tracking.initialState');
const trackingActions = require('./tracking.actions');
const trackingReducer = require('./tracking.reducer');
const trackingWebServices = require('./tracking.webServices');
const { watchTrackingRequests } = require('./tracking.sagas.js');

module.exports = {
  initialState,
  trackingActions,
  trackingReducer,
  trackingWebServices,
  watchTrackingRequests,
}
