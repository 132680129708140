import React, { useState, useEffect, useCallback } from 'react';
import { Toast } from '@shopify/polaris';

export default function Toaster({ settingsToaster, resetSettingsToaster }) {
  const [toasterActive, setToasterActive] = useState(false);
  const [toasterText, setToasterText] = useState(false);
  const [isToasterError, setIsToasterError] = useState(null);

  useEffect(() => {
    setToasterActive(settingsToaster.showToast);
    setIsToasterError(settingsToaster.isErrorToast);
    setToasterText(settingsToaster.toastText);
  }, [settingsToaster, toasterActive]);

  const closeToasterActive = useCallback(() => {
    setToasterActive(false);
    setIsToasterError(false);
    setToasterText(null);
    resetSettingsToaster();
  }, [resetSettingsToaster]);

  const toastMarkup = toasterActive ? (
    <Toast
      error={isToasterError}
      content={toasterText}
      onDismiss={closeToasterActive}
      duration={5000}
    />
  ) : null;

  return <>{toastMarkup}</>;
}
