import { trackingActions } from 'redux/tracking';
import { connect } from 'react-redux';
import PublicLanguagesDropDown from './PublicLanguagesDropDown';

function mapStateToProps() {
  return {};
}

const mapDispatchToProps = {
  getLanguageData: trackingActions.Creators.getLanguageData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicLanguagesDropDown);
