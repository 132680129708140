import { connect } from 'react-redux';
import { settingsActions } from 'redux/settings';
import EmailTestModal from './EmailTestModal';

function mapStateToProps(state) {
  return {
    emailToaster: state.settings.emailToaster,
    emailTriggerDetail: state.settings.emailTriggerDetail,
    loading: state.settings.loading,
  };
}

const mapDispatchToProps = {
  testEmailTriggerById: settingsActions.Creators.testEmailTriggerById,
  getEmailTriggerById: settingsActions.Creators.getEmailTriggerById,
  resetEmailTriggerDetail: settingsActions.Creators.resetEmailTriggerDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailTestModal);
