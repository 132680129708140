const { createReducer } = require('reduxsauce');
const initialState = require('./tracking.initialState');
const trackingActions = require('./tracking.actions');
const stringHelper = require('helpers/string').default;
const i18n = require('i18n').default;

function setTrackingPageToaster(message, isError) {
  return {
    showToast: true,
    isErrorToast: isError,
    toastText: message,
  };
}

const showTrackingPageToaster = (state, action) => {
  const { params } = action;
  let message;
  if (params.error) {
    message = stringHelper.formatAPIError(params)?.description;
  } else {
    message = params.message;
  }
  const toasterObject = setTrackingPageToaster(message, !!params.error);

  return {
    ...state,
    trackingPageToaster: toasterObject,
  };
};

const resetTrackingPageToaster = (state) => {
  return {
    ...state,
    loading: false,
    trackingSaved: false,
    trackingPageToaster: {
      showToast: false,
      isErrorToast: null,
      toastText: null,
    },
  };
};

const getCountriesData = (state) => ({
  ...state,
  loading: true,
});
const getCountriesDataSuccess = (state, action) => ({
  ...state,
  loading: false,
  metaCountries: action.detail.data.countries,
  metaLanguages: action.detail.data.languages,
});

const getCountriesDataFailure = (state, action) => {
  const toasterObject = setTrackingPageToaster(
    stringHelper.getErrorText(action),
    true
  );
  return {
    ...state,
    trackingPageToaster: toasterObject,
    dataLoaded: false,
    loading: false,
  };
};

const getTrackingPageLanguages = (state) => ({ ...state, loading: true });
const getTrackingPageLanguagesSuccess = (state, action) => ({
  ...state,
  loading: false,
  languages: action.data,
});
const getTrackingPageLanguagesFailure = (state) => ({
  ...state,
  loading: false,
});

const getPrivateLanguages = (state) => ({ ...state, loading: true });
const getPrivateLanguagesSuccess = (state, action) => ({
  ...state,
  loading: false,
  privateLanguages: action.data,
});
const getPrivateLanguagesFailure = (state) => ({
  ...state,
  loading: false,
});

const getLanguageData = (state) => ({
  ...state,
  loading: true,
});
const getLanguageDataSuccess = (state, action) => ({
  ...state,
  ...action.detail.data.configuration,
  customLanguageData: action.detail.data,
  loading: false,
});

const getLanguageDataFailure = (state, action) => {
  const toasterObject = setTrackingPageToaster(
    stringHelper.getErrorText(action),
    true
  );
  return {
    ...state,
    trackingPageToaster: toasterObject,
    dataLoaded: false,
    loading: false,
  };
};

const resetLanguageDataForUpdate = (state) => ({
  ...state,
  languageDataForUpdate: null,
});
const getLanguageDataForUpdate = (state) => ({
  ...state,
  loading: true,
});
const getLanguageDataForUpdateSuccess = (state, action) => ({
  ...state,
  languageDataForUpdate: action.detail.data[0],
  loading: false,
});

const getLanguageDataForUpdateFailure = (state) => {
  return {
    ...state,
    loading: false,
  };
};

const getTrackingPageDetail = (state) => ({
  ...state,
  loading: true,
});
const getTrackingPageDetailSuccess = (state, action) => ({
  ...state,
  ...action.detail.data.configuration,
  dataLoaded: true,
  meta: action.detail.data.meta,
  loading: false,
});
const getTrackingPageDetailFailure = (state, action) => {
  const toasterObject = setTrackingPageToaster(
    stringHelper.getErrorText(action),
    true
  );
  return {
    ...state,
    trackingPageToaster: toasterObject,
    dataLoaded: false,
    loading: false,
  };
};

const saveTrackingPageDetail = (state) => {
  return { ...state, loading: true, trackingSaved: false };
};

const saveTrackingPageDetailSuccess = (state, action) => {
  const toasterObject = setTrackingPageToaster(
    i18n.t('settings.tracking_page.store_info.toast_message_updated'),
    false
  );
  return {
    ...state,
    loading: false,
    ...action.detail.data.configuration,
    dataLoaded: true,
    meta: action.detail.data.meta,
    trackingSaved: true,
    trackingPageToaster: toasterObject,
  };
};

const saveTrackingPageDetailFailure = (state, action) => {
  const toasterObject = setTrackingPageToaster(
    stringHelper.getErrorText(action),
    true
  );
  return {
    ...state,
    loading: false,
    trackingSaved: false,
    trackingPageToaster: toasterObject,
  };
};

const addCustomLanguage = (state) => {
  return {
    ...state,
    loading: true,
  };
};

const addCustomLanguageSuccess = (state) => {
  const trackingPageToaster = setTrackingPageToaster(
    i18n.t('settings.tracking_page.custom_language.toast_message_added'),
    false
  );
  return {
    ...state,
    trackingPageToaster,
    loading: false,
    dataLoaded: false, // so tracking page could refresh its data again
  };
};

const addCustomLanguageFailure = (state, action) => {
  const trackingPageToaster = setTrackingPageToaster(
    stringHelper.getErrorText(action),
    true
  );
  return {
    ...state,
    trackingPageToaster,
    loading: false,
  };
};

const deleteCustomLanguage = (state) => {
  return {
    ...state,
    loading: true,
  };
};

const deleteCustomLanguageSuccess = (state) => {
  const trackingPageToaster = setTrackingPageToaster(
    i18n.t('settings.tracking_page.custom_language.toast_message_deleted'),
    false
  );
  return {
    ...state,
    loading: false,
    trackingPageToaster,
    dataLoaded: false, // so tracking page could refresh its data again
  };
};

const deleteCustomLanguageFailure = (state, action) => {
  const trackingPageToaster = setTrackingPageToaster(
    stringHelper.getErrorText(action),
    true
  );
  return {
    ...state,
    trackingPageToaster,
    loading: false,
  };
};

const updateCustomLanguage = (state) => {
  return {
    ...state,
    loading: true,
  };
};

const updateCustomLanguageSuccess = (state) => {
  return {
    ...state,
    trackingPageToaster: setTrackingPageToaster(
      i18n.t('settings.tracking_page.custom_language.toast_message_updated'),
      false
    ),
    loading: false,
  };
};

const updateCustomLanguageFailure = (state, action) => {
  const trackingPageToaster = setTrackingPageToaster(
    stringHelper.getErrorText(action),
    true
  );
  return {
    ...state,
    trackingPageToaster,
    loading: false,
  };
};

// not using these functions.

const uploadLogoRequest = (state) => ({
  ...state,
  loading: true,
  uploaded: false,
});
const uploadLogoProgress = (state, action) => ({
  ...state,
  loading: true,
  storeLogoProgress: action.progress,
});

const uploadLogoSuccess = (state, action) => {
  const responseTextData = JSON.parse(action.responseText).data;
  return {
    ...state,
    loading: false,
    storeLogoProgress: action.progress,
    storeLogoURL: responseTextData.url,
    storeLogoError: null,
    uploaded: true,
  };
};
const uploadLogoFailure = (state, action) => {
  const toasterObject = setTrackingPageToaster(
    stringHelper.getErrorText(action),
    true
  );
  return {
    ...state,
    loading: false,
    trackingPageToaster: toasterObject,
    storeLogoProgress: 0,
    storeLogoError: action.err.message,
    uploaded: false,
  };
};
const uploadLogoReset = (state) => ({
  ...state,
  uploaded: false,
});

const getGeoLocations = (state) => ({
  ...state,
  locationsLoading: true,
});
const getGeoLocationsSuccess = (state, action) => ({
  ...state,
  geoLocations: action.list,
  locationsLoading: false,
});
const getGeoLocationsFailure = (state, action) => {
  const toasterObject = setTrackingPageToaster(
    stringHelper.getErrorText(action),
    true
  );
  return {
    ...state,
    locationsLoading: false,
    trackingPageToaster: toasterObject,
  };
};

const getGeoCities = (state) => ({
  ...state,
  citiesLoading: true,
});
const getGeoCitiesSuccess = (state, action) => ({
  ...state,
  geoCities: action.list.data,
  citiesLoading: false,
});
const getGeoCitiesFailure = (state, action) => {
  const toasterObject = setTrackingPageToaster(
    stringHelper.getErrorText(action),
    true
  );
  return { ...state, citiesLoading: false, trackingPageToaster: toasterObject };
};

const getUpsells = (state) => ({
  ...state,
  upSellLoading: true,
});
const getUpsellsSuccess = (state, action) => ({
  ...state,
  upSellList: action.list.data,
  upSellLoading: false,
});
const getUpsellsFailure = (state, action) => {
  const toasterObject = setTrackingPageToaster(
    stringHelper.getErrorText(action),
    true
  );
  return { ...state, upSellLoading: false, trackingPageToaster: toasterObject };
};

const getUpsellsCollections = (state) => ({
  ...state,
  upSellLoading: true,
});
const getUpsellsCollectionsSuccess = (state, action) => ({
  ...state,
  upSellCollections: action.list.product_collections,
  upSellLoading: false,
});
const getUpsellsCollectionsFailure = (state, action) => {
  const toasterObject = setTrackingPageToaster(
    stringHelper.getErrorText(action),
    true
  );
  return { ...state, upSellLoading: false, trackingPageToaster: toasterObject };
};

const getUpsellsCollectionById = (state) => ({
  ...state,
  upSellLoading: true,
});
const getUpsellsCollectionByIdSuccess = (state, action) => ({
  ...state,
  upSellCollectionDetail: action.collection.product_collection,
  upSellLoading: false,
});
const getUpsellsCollectionByIdFailure = (state, action) => {
  const toasterObject = setTrackingPageToaster(
    stringHelper.getErrorText(action),
    true
  );
  return {
    ...state,
    upSellLoading: false,
    trackingPageToaster: toasterObject,
    upSellCollectionDetail: null,
  };
};

const resetUpsells = (state) => ({
  ...state,
  upSellUpdated: null,
  upSellCollections: [],
  upSellList: [],
  upSellCollectionDetail: null,
  upSellLoading: false,
});
const putUpsells = (state, action) => {
  const toasterObject = setTrackingPageToaster(
    action.params.toasterMessage,
    false
  );
  return {
    ...state,
    upSellLoading: true,
    trackingSaved: true,
    trackingPageToaster: toasterObject,
  };
};
const putUpsellsSuccess = (state, action) => {
  const tempUpSellList = state.upSellList.list.map((upSellItem) => {
    if (action.list.data.item.id === upSellItem.id) {
      return action.list.data.item;
    } else {
      return upSellItem;
    }
  });
  return {
    ...state,
    upSellList: { ...state.upSellList, list: tempUpSellList },
    upSellUpdated: true,
    upSellLoading: false,
  };
};
const putUpsellsFailure = (state, action) => {
  const toasterObject = setTrackingPageToaster(
    stringHelper.getErrorText(action),
    true
  );
  return {
    ...state,
    upSellLoading: false,
    trackingSaved: false,
    trackingPageToaster: toasterObject,
  };
};

const getMerchantNote = (state) => ({
  ...state,
  merchantNoteLoading: true,
});
const getMerchantNoteSuccess = (state, action) => {
  return {
    ...state,
    merchantNoteList: action.list.data,
    merchantNoteLoading: false,
  };
};
const getMerchantNoteFailure = (state, action) => {
  const error = stringHelper.getErrorObject(action);
  const toasterObject = setTrackingPageToaster(error.description, true);
  return {
    ...state,
    merchantNoteLoading: false,
    trackingPageToaster: toasterObject,
    merchantNoteError: error.title,
  };
};

const putMerchantNote = (state, action) => {
  const toasterObject = setTrackingPageToaster(
    action.params.toasterMessage,
    false
  );
  return {
    ...state,
    merchantNoteLoading: false,
    trackingSaved: true,
    trackingPageToaster: toasterObject,
  };
};
const putMerchantNoteSuccess = (state, action) => {
  const tempMerchantNoteList = state.merchantNoteList.list.map(
    (merchantNoteItem) => {
      if (action.list.data.item.id === merchantNoteItem.id) {
        return action.list.data.item;
      } else {
        return merchantNoteItem;
      }
    }
  );
  return {
    ...state,
    merchantNoteList: { ...state.merchantNoteList, list: tempMerchantNoteList },
    merchantNoteUpdated: true,
    merchantNoteLoading: false,
  };
};
const putMerchantNoteFailure = (state, action) => {
  const toasterObject = setTrackingPageToaster(
    stringHelper.getErrorText(action),
    true
  );
  return {
    ...state,
    merchantNoteUpdated: false,
    merchantNoteLoading: false,
    trackingPageToaster: toasterObject,
    trackingSaved: false,
  };
};

const getHTMLTemplate = (state) => ({ ...state, loading: true });
const getHTMLTemplateSuccess = (state, action) => {
  return { ...state, templateHTML: action.template.code, loading: false };
};
const getHTMLTemplateFailure = (state, action) => {
  const toasterObject = setTrackingPageToaster(
    stringHelper.getErrorText(action),
    true
  );
  return { ...state, loading: false, trackingPageToaster: toasterObject };
};

const { Types } = trackingActions;

// map our action types to our reducer functions
const HANDLERS = {
  [Types.GET_TRACKING_PAGE_DETAIL]: getTrackingPageDetail,
  [Types.GET_TRACKING_PAGE_DETAIL_SUCCESS]: getTrackingPageDetailSuccess,
  [Types.GET_TRACKING_PAGE_DETAIL_FAILURE]: getTrackingPageDetailFailure,

  [Types.GET_COUNTRIES_DATA]: getCountriesData,
  [Types.GET_COUNTRIES_DATA_SUCCESS]: getCountriesDataSuccess,
  [Types.GET_COUNTRIES_DATA_FAILURE]: getCountriesDataFailure,

  [Types.GET_LANGUAGE_DATA]: getLanguageData,
  [Types.GET_LANGUAGE_DATA_SUCCESS]: getLanguageDataSuccess,
  [Types.GET_LANGUAGE_DATA_FAILURE]: getLanguageDataFailure,

  [Types.RESET_LANGUAGE_DATA_FOR_UPDATE]: resetLanguageDataForUpdate,
  [Types.GET_LANGUAGE_DATA_FOR_UPDATE]: getLanguageDataForUpdate,
  [Types.GET_LANGUAGE_DATA_FOR_UPDATE_SUCCESS]: getLanguageDataForUpdateSuccess,
  [Types.GET_LANGUAGE_DATA_FOR_UPDATE_FAILURE]: getLanguageDataForUpdateFailure,

  [Types.ADD_CUSTOM_LANGUAGE]: addCustomLanguage,
  [Types.ADD_CUSTOM_LANGUAGE_SUCCESS]: addCustomLanguageSuccess,
  [Types.ADD_CUSTOM_LANGUAGE_FAILURE]: addCustomLanguageFailure,

  [Types.UPDATE_CUSTOM_LANGUAGE]: updateCustomLanguage,
  [Types.UPDATE_CUSTOM_LANGUAGE_SUCCESS]: updateCustomLanguageSuccess,
  [Types.UPDATE_CUSTOM_LANGUAGE_FAILURE]: updateCustomLanguageFailure,

  [Types.DELETE_CUSTOM_LANGUAGE]: deleteCustomLanguage,
  [Types.DELETE_CUSTOM_LANGUAGE_SUCCESS]: deleteCustomLanguageSuccess,
  [Types.DELETE_CUSTOM_LANGUAGE_FAILURE]: deleteCustomLanguageFailure,

  [Types.GET_TRACKING_PAGE_DETAIL]: getTrackingPageDetail,
  [Types.GET_TRACKING_PAGE_DETAIL_SUCCESS]: getTrackingPageDetailSuccess,
  [Types.GET_TRACKING_PAGE_DETAIL_FAILURE]: getTrackingPageDetailFailure,

  [Types.SAVE_TRACKING_PAGE_DETAIL]: saveTrackingPageDetail,
  [Types.SAVE_TRACKING_PAGE_DETAIL_SUCCESS]: saveTrackingPageDetailSuccess,
  [Types.SAVE_TRACKING_PAGE_DETAIL_FAILURE]: saveTrackingPageDetailFailure,

  [Types.UPLOAD_LOGO_REQUEST]: uploadLogoRequest,
  [Types.UPLOAD_LOGO_PROGRESS]: uploadLogoProgress,
  [Types.UPLOAD_LOGO_SUCCESS]: uploadLogoSuccess,
  [Types.UPLOAD_LOGO_FAILURE]: uploadLogoFailure,
  [Types.UPLOAD_LOGO_RESET]: uploadLogoReset,

  [Types.GET_GEO_LOCATIONS]: getGeoLocations,
  [Types.GET_GEO_LOCATIONS_SUCCESS]: getGeoLocationsSuccess,
  [Types.GET_GEO_LOCATIONS_FAILURE]: getGeoLocationsFailure,

  [Types.GET_GEO_CITIES]: getGeoCities,
  [Types.GET_GEO_CITIES_SUCCESS]: getGeoCitiesSuccess,
  [Types.GET_GEO_CITIES_FAILURE]: getGeoCitiesFailure,

  [Types.GET_UPSELLS]: getUpsells,
  [Types.GET_UPSELLS_SUCCESS]: getUpsellsSuccess,
  [Types.GET_UPSELLS_FAILURE]: getUpsellsFailure,

  [Types.GET_UPSELLS_COLLECTIONS]: getUpsellsCollections,
  [Types.GET_UPSELLS_COLLECTIONS_SUCCESS]: getUpsellsCollectionsSuccess,
  [Types.GET_UPSELLS_COLLECTIONS_FAILURE]: getUpsellsCollectionsFailure,

  [Types.GET_UPSELLS_COLLECTION_BY_ID]: getUpsellsCollectionById,
  [Types.GET_UPSELLS_COLLECTION_BY_ID_SUCCESS]: getUpsellsCollectionByIdSuccess,
  [Types.GET_UPSELLS_COLLECTION_BY_ID_FAILURE]: getUpsellsCollectionByIdFailure,

  [Types.RESET_UPSELLS]: resetUpsells,
  [Types.PUT_UPSELLS]: putUpsells,
  [Types.PUT_UPSELLS_SUCCESS]: putUpsellsSuccess,
  [Types.PUT_UPSELLS_FAILURE]: putUpsellsFailure,

  [Types.GET_MERCHANT_NOTE]: getMerchantNote,
  [Types.GET_MERCHANT_NOTE_SUCCESS]: getMerchantNoteSuccess,
  [Types.GET_MERCHANT_NOTE_FAILURE]: getMerchantNoteFailure,

  [Types.PUT_MERCHANT_NOTE]: putMerchantNote,
  [Types.PUT_MERCHANT_NOTE_SUCCESS]: putMerchantNoteSuccess,
  [Types.PUT_MERCHANT_NOTE_FAILURE]: putMerchantNoteFailure,

  [Types.GET_HTML_TEMPLATE]: getHTMLTemplate,
  [Types.GET_HTML_TEMPLATE_SUCCESS]: getHTMLTemplateSuccess,
  [Types.GET_HTML_TEMPLATE_FAILURE]: getHTMLTemplateFailure,

  [Types.RESET_TRACKING_PAGE_TOASTER]: resetTrackingPageToaster,
  [Types.SHOW_TRACKING_PAGE_TOASTER]: showTrackingPageToaster,

  [Types.GET_TRACKING_PAGE_LANGUAGES]: getTrackingPageLanguages,
  [Types.GET_TRACKING_PAGE_LANGUAGES_SUCCESS]: getTrackingPageLanguagesSuccess,
  [Types.GET_TRACKING_PAGE_LANGUAGES_FAILURE]: getTrackingPageLanguagesFailure,

  [Types.GET_PRIVATE_LANGUAGES]: getPrivateLanguages,
  [Types.GET_PRIVATE_LANGUAGES_SUCCESS]: getPrivateLanguagesSuccess,
  [Types.GET_PRIVATE_LANGUAGES_FAILURE]: getPrivateLanguagesFailure,
};

module.exports = createReducer(initialState, HANDLERS);
