import { storeApi } from '../../redux/store/storeApi';

export const productReviewsApi = storeApi.injectEndpoints({
  endpoints: (builder) => ({
    getReviewsConfig: builder.query({
      query: () => {
        return {
          url: `/features/product-reviews/config`,
          method: 'GET',
        };
      },
      providesTags: ['productReviews'],
    }),
    updateReviewsConfig: builder.mutation({
      query: (config) => {
        return {
          url: `/features/product-reviews/config`,
          method: 'PATCH',
          body: { ...config },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      invalidatesTags: ['productReviews', 'settings'],
    }),
  }),
});

export const { useGetReviewsConfigQuery, useUpdateReviewsConfigMutation } =
  productReviewsApi;
