import { baseApi } from '../../redux/store/baseApi';

export const questionnaireApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getQuestionnaire: builder.query({
      query: () => {
        return {
          url: '/user/me/questionnaires',
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    updateQuestionnaire: builder.mutation({
      query: (data) => {
        return {
          url: `/user/me/questionnaires`,
          method: 'PUT',
          body: { ...data },
        };
      },
    }),
  }),
});

export const { useGetQuestionnaireQuery, useUpdateQuestionnaireMutation } =
  questionnaireApi;
