import { connect } from 'react-redux';
import { settingsActions } from 'redux/settings';
import EmailDeleteModal from './EmailDeleteModal';

function mapStateToProps(state) {
  return {
    emailToaster: state.settings.emailToaster,
  };
}

const mapDispatchToProps = {
  deleteEmailTriggerById: settingsActions.Creators.deleteEmailTriggerById,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailDeleteModal);
