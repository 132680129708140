import { storeApi } from '../../redux/store/storeApi';

export const onBoardingApi = storeApi.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardTasks: builder.query({
      query: () => {
        return {
          url: `/tasks/dashboard`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      providesTags: ['dashboardTasks'],
    }),
    completeTask: builder.mutation({
      query: (taskId) => {
        return {
          url: `/tasks/dashboard/complete/${taskId}`,
          method: 'PUT',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    dismissTask: builder.mutation({
      query: (taskId) => {
        return {
          url: `/tasks/dashboard/dismiss/${taskId}`,
          method: 'PUT',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
  }),
});

export const {
  useGetDashboardTasksQuery,
  useCompleteTaskMutation,
  useDismissTaskMutation,
} = onBoardingApi;
