import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
  Card,
  Autocomplete,
  Icon,
  ButtonGroup,
  Button,
  ChoiceList,
  Text,
  Box,
  InlineStack,
  BlockStack,
  Checkbox,
} from '@shopify/polaris';
import { SearchIcon, DeleteIcon, ExternalSmallIcon } from '@shopify/polaris-icons';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';
import SmallTopMessage from 'components/SmallTopMessage';
import Subtitle from 'components/Subtitle';
import TranslateLink from 'components/TranslateLink';
import {
  useGetGeoLocationsQuery,
  useLazyGetGeoCitiesQuery,
  useGetBlacklistQuery,
  useUpdateBlackListingMutation,
} from '../blackListingApi';
import SkeletonBlackListPage from './SkeletonBlackListPage';
import FindReplaceModal from './FindReplaceModal';
import AutoCompleteWithTags from 'components/AutoCompleteWithTags';
import RushPage from 'components/custom/RushPage';
import { baseActions } from 'redux/store/baseSlice';
import { useDispatch, useSelector } from 'react-redux';
import RushSaveBar from 'components/custom/RushSaveBar';
import { STORE_URLS } from 'config/urls';
import { useParams } from 'react-router-dom';
import useIsEmbededApp from 'hooks/useIsEmbededApp';
import RushLoading from 'components/custom/RushLoader';
import { StoreUUID } from 'redux/store/shopSlice';
export default function Blacklisting() {
  const [t] = useTranslation();
  const params = useParams();
  const isEmbededApp = useIsEmbededApp();
  const dispatch = useDispatch();

  const storeUUID = useSelector(StoreUUID);

  const [skipInternationalMessages, setSkipInternationalMessages] =
    useState(false);
  const [blacklistType, setBlacklistType] = useState(0);
  const [carriersType, setCarriersType] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [replaceLocation, setReplaceLocation] = useState([]);
  const [withLocation, setWithLocation] = useState({});
  const [cityInputValue, setCityInputValue] = useState('');
  const [findAndReplace, setFindAndReplace] = useState([]);
  const [findAndReplaceIndex, setFindAndReplaceIndex] = useState(-1);
  const [formErrors, setFormErrors] = useState({
    replaceLocation: false,
    withLocation: false,
  });

  /*
   * Component API calls
   */
  const blackListResponse = useGetBlacklistQuery();
  const {
    data: response = {},
    isLoading: isBlackListLoading,
    isFetching: isBlackListFetching,
    isSuccess: isBlackListSuccess,
  } = blackListResponse;
  const {
    blacklist = {},
    carriers = {},
    find_and_replace = [],
    skip_international_messages,
  } = response;
  const geoLocationResponse = useGetGeoLocationsQuery();
  const {
    data: geoLocations = [],
    isLoading: isGeoLocationsLoading,
    isSuccess: isGeoLocationsSuccess,
  } = geoLocationResponse;

  const [getGeoCities, citiesResponse] = useLazyGetGeoCitiesQuery();
  const {
    data: geoCities = {},
    isFetching: isCitiesLoading,
    isSuccess: isCitiesSuccess,
  } = citiesResponse;

  const [updateBlackListing, updateResponse] = useUpdateBlackListingMutation();
  const { isLoading: isBlackListUpdating, isSuccess: isBlackListUpdated } =
    updateResponse;

  /*
   * Component Handlers
   */

  useEffect(() => {
    isBlackListUpdated &&
      dispatch(
        baseActions.setToastMessage({
          message: t('settings.tracking_page.section_blacklist_updated-toast'),
          type: 'success',
        })
      );
  }, [isBlackListUpdated]);

  const geoLocationOptions = useMemo(() => {
    if (isGeoLocationsSuccess && Array.isArray(geoLocations)) {
      return geoLocations.map((item) => ({
        value: item.loc_id,
        label: item.label,
      }));
    } else {
      return [];
    }
  }, [geoLocations, isGeoLocationsSuccess]);

  const cityOptions = useMemo(() => {
    if (
      isCitiesSuccess &&
      Array.isArray(geoCities) &&
      cityInputValue &&
      cityInputValue.length > 2
    ) {
      return geoCities.map((item) => ({
        value: item.place_id,
        label: item.label,
      }));
    } else {
      return [];
    }
  }, [geoCities, isCitiesSuccess, cityInputValue]);

  useEffect(() => {
    if (isBlackListSuccess && !isBlackListLoading) {
      setReplaceLocation(blacklist.replace_location || []);
      setWithLocation(blacklist.with_location || {});
      setBlacklistType(blacklist.type);
      setCarriersType(carriers.type);
      setSkipInternationalMessages(skip_international_messages);
      setFindAndReplace(find_and_replace || []);
      setCityInputValue(blacklist.with_location?.label || '');
    }
  }, [isBlackListSuccess, isBlackListLoading]);

  const getEditItem = () => {
    if (findAndReplaceIndex === -1) {
      return {
        find: '',
        is_regexp: false,
        replace: '',
      };
    }
    return findAndReplace[findAndReplaceIndex];
  };

  const debouncesGetCities = useCallback(
    debounce((searchVal) => {
      getGeoCities(searchVal);
    }, 500),
    []
  );

  useEffect(() => {
    try {
      const isSelectionCase = cityOptions.find((option) => {
        return option.label === cityInputValue;
      });
      if (cityInputValue && cityInputValue.length > 2 && !isSelectionCase) {
        debouncesGetCities(cityInputValue);
      }
      // eslint-disable-next-line no-empty
    } catch { }
  }, [cityInputValue]);

  const handleLocationsChange = useCallback(
    (selection) => {
      setReplaceLocation(selection);
      setFormErrors({ ...formErrors, replaceLocation: false });
    },
    [setReplaceLocation, setFormErrors, formErrors]
  );

  const handleCityChange = useCallback(
    (selection) => {
      try {
        const matchedOption = cityOptions.find((option) => {
          return option.value === selection[0];
        });
        setCityInputValue(matchedOption.label);
        setWithLocation({ label: matchedOption.label, place_id: selection[0] });
        setFormErrors({ ...formErrors, withLocation: false });
      } catch (error) {
        console.log(error);
      }
    },
    [setWithLocation, setCityInputValue, cityOptions, setFormErrors, formErrors]
  );

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleFindAndReplaceChange = (updated) => {
    const updatedArr = [...findAndReplace];
    if (findAndReplaceIndex === -1) {
      updatedArr.push({ ...updated });
    } else {
      updatedArr.splice(findAndReplaceIndex, 1, {
        ...updated,
      });
    }
    setFindAndReplace(updatedArr);
    toggleModal();
  };

  const validateData = () => {
    if (blacklistType === 0) {
      return true;
    } else if (blacklistType === 1) {
      if (replaceLocation.length === 0) {
        setFormErrors({
          ...formErrors,
          replaceLocation: true,
        });
        return false;
      } else {
        setFormErrors({ replaceLocation: false, withLocation: false });
        return true;
      }
    } else if (blacklistType === 2) {
      if (!withLocation.place_id || replaceLocation.length === 0) {
        setFormErrors({
          withLocation: !withLocation.place_id,
          replaceLocation: replaceLocation.length === 0,
        });
        return false;
      } else {
        setFormErrors({ replaceLocation: false, withLocation: false });
        return true;
      }
    } else {
      setFormErrors({ replaceLocation: false, withLocation: false });
      return true;
    }
  };

  const isDirtyState = () => {
    try {
      if (isBlackListSuccess) {
        const prev = {
          blacklist: {
            replace_location: response.blacklist?.replace_location || [],
            type: response.blacklist?.type,
            with_location: response.blacklist?.with_location || {},
          },
          carriers: response.carriers,
          findReplace: response.find_and_replace || [],
          skip_international_messages: response.skip_international_messages,
        };

        const current = {
          blacklist: {
            replace_location: replaceLocation,
            type: blacklistType,
            with_location: withLocation,
          },
          carriers: { type: carriersType },
          findReplace: findAndReplace || [],
          skip_international_messages: skipInternationalMessages,
        };

        console.log({ prev, current });

        return !isEqual(prev, current);
      }
    } catch {
      return false;
    }
  };

  const handleSave = () => {
    if (validateData()) {
      updateBlackListing({
        blacklist: {
          type: blacklistType,
          replace_location: replaceLocation || [],
          with_location: withLocation || {},
        },
        find_and_replace: findAndReplace || [],
        carriers: { type: carriersType },
        skip_international_messages: skipInternationalMessages,
      });
    }
  };
  const handleDiscard = () => {
    try {
      if (isBlackListSuccess) {
        setReplaceLocation(blacklist.replace_location || []);
        setWithLocation(blacklist.with_location || {});
        setBlacklistType(blacklist.type);
        setCarriersType(carriers.type);
        setFindAndReplace(find_and_replace || []);
        setSkipInternationalMessages(skip_international_messages);
        setCityInputValue(blacklist.with_location?.label || '');
      } else {
        setReplaceLocation([]);
        setWithLocation({});
        setFindAndReplace([]);
        setBlacklistType(0);
        setCarriersType(0);
        setCityInputValue('');
        setSkipInternationalMessages(false);
      }
    } catch {
      return false;
    }
  };

  const deleteFindAndReplace = (index) => {
    let tempFindAndReplace = [...findAndReplace];
    tempFindAndReplace.splice(index, 1);
    setFindAndReplace(tempFindAndReplace);
  };

  const onAddFindAndReplace = () => {
    setFindAndReplaceIndex(-1);
    toggleModal();
  };

  const handleClearButtonClick = () => {
    setCityInputValue('');
    setWithLocation({});
  };

  const handleBlacklistTypeChange = ([option]) => {
    setBlacklistType(option);
    setFormErrors({ withLocation: false, replaceLocation: false });
  };

  const handleCarrierTypeChange = ([option]) => {
    setCarriersType(option);
  };

  /*
   * Component Renderers
   */
  const cityTextField = (
    <Autocomplete.TextField
      autoComplete="off"
      onChange={(value) => {
        setCityInputValue(value);
        if (value === '') {
          setWithLocation({});
        }
      }}
      clearButton
      onClearButtonClick={handleClearButtonClick}
      label={t('settings.tracking_page.section_blacklist_location2_title')}
      value={cityInputValue}
      prefix={<Icon source={SearchIcon} color="inkLighter" />}
      placeholder={t(
        'settings.tracking_page.section_blacklist_location2_title'
      )}
      type="search"
      id="input-blacklist-city"
      error={
        formErrors.withLocation && !withLocation?.place_id
          ? t(
            'settings.tracking_page.section_blacklist_location2.empty_error_label'
          )
          : null
      }
    />
  );

  const toggleChangeLocation = () => {
    setBlacklistType(blacklistType === 0 ? 1 : 0);
  };
  const toggleCarrier = () => {
    setCarriersType(carriersType === 0 ? 1 : 0);
  };
  const citiesEmptyState = (
    <Text variant="bodyMd" as="span" color="subdued">
      <div style={{ padding: '2rem' }}>
        <Icon source={SearchIcon} color="subdued" />
        <div style={{ textAlign: 'center' }}>
          {!cityInputValue ||
            (cityInputValue && cityInputValue.length < 3) ||
            isCitiesLoading
            ? 'Keep typing to find any city location you want'
            : 'Could not find any results'}
        </div>
      </div>
    </Text>
  );

  const locationsEmptyState = (
    <Text variant="bodyMd" as="span" tone="subdued">
      <div style={{ padding: '2rem' }}>
        <Icon source={SearchIcon} color="subdued" />
        <div style={{ textAlign: 'center' }}>
          {'Could not find any results'}
        </div>
      </div>
    </Text>
  );

  const renderStatusString = () => {
    try {
      if (replaceLocation.length) {
        const tobeReplacedStr = replaceLocation
          .map(
            (option) =>
              ((geoLocationOptions || []).find((c) => c.value === option) || {})
                .label
          )
          .join(', ');

        if (withLocation.place_id && blacklistType === 2) {
          return (
            <>
              <Text variant="bodyMd" as="span" tone="subdued">
                {t(
                  'settings.tracking_page.section_blacklist_replace_with_other_status',
                  {
                    replace_list: tobeReplacedStr,
                    replace_location:
                      (
                        cityOptions.find(
                          (city) => city.value === withLocation.place_id
                        ) || {}
                      ).label || '',
                  }
                )}
              </Text>
            </>
          );
        } else if (blacklistType === 1) {
          return (
            <>
              <Text variant="bodyMd" as="span" tone="subdued">
                {t(
                  'settings.tracking_page.section_blacklist_replace_with_empty_status',
                  {
                    replace_list: tobeReplacedStr,
                  }
                )}
              </Text>
            </>
          );
        }
      }
    } catch {
      console.log('error in renderStatusString');
    }
  };

  const renderCarriersCard = () => {
    const maksingLink = `/s/${storeUUID}${STORE_URLS.CARRIER_MASKING}`;

    return (
      <Card roundedAbove="sm" padding={0}>
        <Box padding={'500'}>
          <BlockStack gap={'200'}>
            <Text variant="headingMd" as="h2">
              {t('blacklist_carriers.title')}
            </Text>
            <Text variant="bodyMd" as="span" color="subdued">
              <TranslateLink
                text={t('blacklist_carriers.description')}
              ></TranslateLink>
            </Text> 
          </BlockStack>
        </Box>
        <Box
          padding={'500'}
          paddingBlockStart={'0'}
          borderBlockEndWidth="0"
          borderColor="border-brand"
        >
          <BlockStack gap={'300'}>
            <Checkbox
              label={t('blacklist_carriers.replace_checkbox.title')}
              checked={carriersType !== 0}
              onChange={toggleCarrier}
            />{' '}
            {!!carriersType && (
              <>
                <div className="small-gap-list">
                  <ChoiceList
                    choices={[
                      {
                        label: t('blacklist_carriers.replace_option1'),
                        value: 1,
                      },
                      {
                        label: t('blacklist_carriers.replace_option2'),
                        value: 2,
                      },
                      {
                        label: (
                          <TranslateLink
                            text={t('blacklist_carriers.replace_option3', {
                              carrier_making: maksingLink,
                            })}
                            pathType={'app'}
                            external={false}
                            target={'_self'}
                          />
                        ),
                        value: 3,
                      },
                    ]}
                    selected={[carriersType]}
                    onChange={handleCarrierTypeChange}
                  />
                </div>
              </>
            )}
          </BlockStack>
        </Box>
      </Card>
    );
  };
  const renderSkipMessagesCard = () => {
    return (
      <Card roundedAbove="sm" padding={0}>
        <Box padding={'500'}>
          <BlockStack gap={'200'}>
            <Text variant="headingMd" as="h2">
              {t('blacklist.skip_message.title')}
            </Text>
            <Text variant="bodyMd" as="span" color="subdued">
              <TranslateLink
                text={t('blacklist.skip_message.description')}
              ></TranslateLink>
            </Text>
          </BlockStack>
        </Box>
        <Box
          padding={'500'}
          paddingBlockStart={'0'}
          borderBlockEndWidth="0"
          borderColor="border-brand"
        >
          <BlockStack gap={'300'}>
            <Checkbox
              label={t('blacklist.skip_message.checkbox')}
              checked={skipInternationalMessages}
              onChange={() =>
                setSkipInternationalMessages(!skipInternationalMessages)
              }
            />{' '}
            <Text variant="bodyMd" as="span" tone="subdued">
              {t('blacklist.skip_message.footer')}
            </Text>
          </BlockStack>
        </Box>
      </Card>
    );
  };

  return (
    <React.Fragment>
      {isBlackListLoading || isGeoLocationsLoading || isBlackListUpdating ? (
        <RushLoading />
      ) : (
        ''
      )}
      <SmallTopMessage />
      {isBlackListLoading || isGeoLocationsLoading ? (
        <SkeletonBlackListPage />
      ) : (
        <RushPage
          title={t('settings.tracking_page.section_blacklist_title')}
          backAction={
            isEmbededApp
              ? {
                url: `/s/${params.uuid}${STORE_URLS.SHIPMENTS}`,
              }
              : undefined
          }
          subtitle={
            <Subtitle
              text={t('settings.tracking_page.section_blacklist_subtitle')}
              actions={[
                {
                  text: t(
                    'settings.tracking_page.section_blacklist_get_started'
                  ),
                  source: 'https://support.rush.app/shipments/blacklist/about.html',
                },
              ]}
            />
          }
        >
          <BlockStack gap={'400'}>
            <Card roundedAbove="sm" padding={0}>
              <Box padding={'500'}>
                <BlockStack gap={'200'}>
                  <Text variant="headingMd" as="h2">
                    {t(
                      'settings.tracking_page.section_blacklist_location_title'
                    )}
                  </Text>
                  <Text variant="bodyMd" as="span" tone="subdued">
                    <TranslateLink
                      text={t(
                        'settings.tracking_page.section_blacklist_description'
                      )}
                    ></TranslateLink>
                  </Text>
                </BlockStack>
              </Box>
              <Box
                padding={'500'}
                paddingBlockStart={'0'}
                borderBlockEndWidth="0"
                borderColor="border-brand"
              >
                <BlockStack gap={'300'}>
                  <Checkbox
                    label={t('blacklist.change_location_checkbox')}
                    checked={blacklistType !== 0}
                    onChange={toggleChangeLocation}
                  />{' '}
                  {blacklistType !== 0 && (
                    <>
                      <div className="small-gap-list">
                        <ChoiceList
                          title={t(
                            'settings.tracking_page.section_blacklist_replace_with_title'
                          )}
                          choices={[
                            {
                              label: t(
                                'settings.tracking_page.section_blacklist_empty_location'
                              ),
                              value: 1,
                            },
                            {
                              label: t(
                                'settings.tracking_page.section_blacklist_other_city'
                              ),
                              value: 2,
                            },
                          ]}
                          selected={[blacklistType]}
                          onChange={handleBlacklistTypeChange}
                        />
                      </div>
                      <AutoCompleteWithTags
                        optionList={geoLocationOptions}
                        selected={replaceLocation}
                        onChange={handleLocationsChange}
                        emptyState={locationsEmptyState}
                        label={t(
                          'settings.tracking_page.section_blacklist_location_input_title'
                        )}
                        placeholder={t(
                          'settings.tracking_page.section_blacklist_location_input_title'
                        )}
                        error={
                          formErrors.replaceLocation && !replaceLocation?.length
                        }
                        errorMessage={t(
                          'settings.tracking_page.section_blacklist_location.empty_error_label'
                        )}
                        limitItems={false}
                      />
                      {blacklistType === 2 ? (
                        <>
                          <Autocomplete
                            options={cityOptions}
                            selected={[withLocation?.place_id]}
                            onSelect={handleCityChange}
                            textField={cityTextField}
                            loading={isCitiesLoading}
                            emptyState={!isCitiesLoading && citiesEmptyState}
                          />
                        </>
                      ) : (
                        ''
                      )}
                      {renderStatusString()}
                    </>
                  )}
                </BlockStack>
              </Box>
            </Card>
            {renderCarriersCard()}
            {renderSkipMessagesCard()}
            <Card roundedAbove="sm" padding={0}>
              <Box padding={0}>
                <Box padding={'500'} paddingBlockEnd={'0'}>
                  <BlockStack gap={'200'}>
                    <InlineStack gap={'400'} align="space-between">
                      <Text variant="headingMd" as="h2">
                        {t('settings.tracking_page.find_and_replace.title')}
                      </Text>
                      <Button variant="plain" onClick={onAddFindAndReplace}>
                        {t(
                          'settings.tracking_page.find_and_replace.add_btn_label'
                        )}
                      </Button>
                    </InlineStack>
                    <Text variant="bodyMd" as="span" tone="subdued">
                      <TranslateLink
                        text={t('blacklist.swap_words.description')}
                      />
                    </Text>
                  </BlockStack>
                </Box>
                <>
                  {findAndReplace &&
                    findAndReplace.map((find_replace, index) => (
                      <div className="SearchReplace-List" key={index}>
                        <div className="SearchReplace-List-Heading">
                          <Text variant="bodyMd" as="span">
                            <i>{find_replace.find}</i>{' '}
                            {t(
                              'settings.tracking_page.find_and_replace_modal.with'
                            )}{' '}
                            <i>{find_replace.replace}</i>
                          </Text>
                        </div>
                        <div className="SearchReplace-List-Buttons">
                          <ButtonGroup variant='segmented'>
                            <Button
                              size="slim"
                              onClick={() => {
                                setFindAndReplaceIndex(index);
                                toggleModal();
                              }}
                              icon={<Icon source={ExternalSmallIcon} />}
                            />
                            <Button
                              size="slim"
                              onClick={() => deleteFindAndReplace(index)}
                              icon={<Icon source={DeleteIcon} />}
                            />
                          </ButtonGroup>
                        </div>
                      </div>
                    ))}
                </>
                {(!findAndReplace || findAndReplace.length === 0) && (
                  <div className="CustomEmptyState">
                    <Box padding={'500'}>
                      <Text variant="bodyMd" as="span" color="subdued">
                        {t(
                          'settings.tracking_page.section_blacklist_no_replace_message'
                        )}
                      </Text>
                    </Box>
                  </div>
                )}
              </Box>
            </Card>
          </BlockStack>
          {isDirtyState() && (
            <RushSaveBar
              message={t('carrier-maskings.unsaved-changes')}
              saveAction={{
                content: t('carrier-maskings.save'),
                onAction: handleSave,
                loading: isBlackListFetching || isBlackListUpdating,
              }}
              discardAction={
                isBlackListFetching || isBlackListUpdating
                  ? undefined
                  : {
                    onAction: handleDiscard,
                    content: t('common.discard'),
                  }
              }
            />
          )}
          {showModal && (
            <FindReplaceModal
              item={getEditItem()}
              onChange={handleFindAndReplaceChange}
              onClose={toggleModal}
            />
          )}
        </RushPage>
      )}
    </React.Fragment>
  );
}
