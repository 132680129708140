import { connect } from 'react-redux';
import SaveBar from './SaveBar';

function mapStateToProps(state) {
  return {
    settingsLoading: state.settings.loading,
  };
}

export default connect(mapStateToProps)(SaveBar);
