import { connect } from 'react-redux';
import { settingsActions } from 'redux/settings';
import EmailNotification from './EmailNotification';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    loading: state.settings.loading,
    emailConfig: state.settings.emailConfig,
    emailTriggers: state.settings.emailTriggers,
    emailToaster: state.settings.emailToaster,
    emailError: state.settings.emailError,
  };
}

const mapDispatchToProps = {
  getEmailConfig: settingsActions.Creators.getEmailConfig,
  activateEmailTrigger: settingsActions.Creators.activateEmailTrigger,
  deactivateEmailTrigger: settingsActions.Creators.deactivateEmailTrigger,
  resetEmailToaster: settingsActions.Creators.resetEmailToaster,
  deleteEmailTriggerById: settingsActions.Creators.deleteEmailTriggerById,
  testEmailTriggerById: settingsActions.Creators.testEmailTriggerById,
  resetEmailTriggerDetail: settingsActions.Creators.resetEmailTriggerDetail,
  updateEmailSender: settingsActions.Creators.updateEmailSender,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailNotification);
