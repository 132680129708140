import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  queryString: null,
  queryObject: null,
  globalSearch: { categoryIndex: 0, text: '' },
};

const shipmentsSlice = createSlice({
  name: 'shipments',
  initialState,
  reducers: {
    setShipmentsQuery(state, action) {
      state.queryString = action.payload?.queryString;
      state.queryObject = action.payload?.queryObject;
    },
    setGlobalSearch(state, action) {
      state.globalSearch = {
        ...state.globalSearch,
        ...action.payload,
      };
    },
  },
});

// Actions
export const shipmentsActions = shipmentsSlice.actions;

// Selectors
export const ShipmentsQueryString = (state) => state.shipments.queryString;
export const ShipmentsQueryObject = (state) => state.shipments.queryObject;
export const GlobalSearch = (state) => state.shipments.globalSearch;

// Reducer
const shipmentsReducer = shipmentsSlice.reducer;
export default shipmentsReducer;
