import { connect } from 'react-redux';
import { settingsActions } from 'redux/settings';
import UnsavedChangesModal from './UnsavedChangesModal';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    unsavedModalOpen: state.settings.unsavedModalOpen,
    unsavedModalFns: state.settings.unsavedModalFns,
  };
}

const mapDispatchToProps = {
  showUnsavedModal: settingsActions.Creators.showUnsavedModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnsavedChangesModal);
