import { connect } from 'react-redux';
import Banner from './Banner';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    tasks: state.settings.tasksList,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
