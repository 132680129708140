import { connect } from 'react-redux';
import KlaviyoActions from './KlaviyoActions';
import { settingsActions } from 'redux/settings';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    loading: state.settings.loading,
  };
}

const mapDispatchToProps = {
  sendAllEventsTestKlaviyo: settingsActions.Creators.sendAllEventsTestKlaviyo,
};

export default connect(mapStateToProps, mapDispatchToProps)(KlaviyoActions);
