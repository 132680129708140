import { connect } from 'react-redux';
import { settingsActions } from 'redux/settings';
import ThankyouPage from './ThankyouPage';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    featureList: state.settings.featureList,
    orderStatusConfigurations: state.settings.orderStatusConfigurations,
    orderStatusMeta: state.settings.orderStatusMeta,
    loadingSettings: state.settings.loadingSettings,
    loadingThankYouPage: state.settings.loadingThankYouPage,
    orderStatusPagePreviewLink: state.settings.orderStatusPagePreviewLink,
    featureRequest: state.settings.featureRequest,
  };
}

const mapDispatchToProps = {
  getOrderStatusPageConfigurations:
    settingsActions.Creators.getOrderStatusPageConfigurations,
  putOrderStatusPageConfigurations:
    settingsActions.Creators.putOrderStatusPageConfigurations,
  getOrderStatusPagePreviewLink:
    settingsActions.Creators.getOrderStatusPagePreviewLink,
  activateFeature: settingsActions.Creators.activateFeature,
  deactivateFeature: settingsActions.Creators.deactivateFeature,
  showUnsavedModal: settingsActions.Creators.showUnsavedModal,
  resetFeatureRequest: settingsActions.Creators.resetFeatureRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ThankyouPage);
