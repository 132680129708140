import { connect } from 'react-redux';
import { trackingActions } from 'redux/tracking';
import MerchantNote from './MerchantNote';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    trackingDetail: state.trackingDetail,
    merchantNoteList: state.trackingDetail.merchantNoteList,
    merchantNoteUpdated: state.trackingDetail.merchantNoteUpdated,
    merchantNoteError: state.trackingDetail.merchantNoteError,
  };
}

const mapDispatchToProps = {
  getMerchantNote: trackingActions.Creators.getMerchantNote,
  putMerchantNote: trackingActions.Creators.putMerchantNote,
};

export default connect(mapStateToProps, mapDispatchToProps)(MerchantNote);
