import { baseApi } from '../../redux/store/baseApi';

export const PermissionsApis = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    connectStore: builder.query({
      query: ({ uuid }) => {
        return {
          url: `/auth/shopify/approve?store_uuid=${uuid}`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
  }),
});

export const { useLazyConnectStoreQuery } = PermissionsApis;
