module.exports = {
  loading: false,
  tasksList: {},
  featureList: [],
  featureError: null,
  showSlackModal: false,
  slackTestingConfirmation: null,
  slackConfigSaved: null,
  slackConfig: {},
  slackMeta: {
    languages: [],
    triggers: [],
  },
  slackError: null,
  klaviyoTestingConfirmation: null,
  klaviyoConfigSaved: null,
  klaviyoConfig: {},
  klaviyoMeta: {
    languages: [],
    triggers: [],
  },
  klaviyoSummaryFlow: null,
  klaviyoError: null,
  omnisendTestingConfirmation: null,
  omnisendTestingConfirmationMessage: null,
  omnisendConfigSaved: null,
  omnisendConfig: {},
  omnisendMeta: {
    languages: [],
    triggers: [],
  },
  omnisendError: null,
  emailToaster: {
    showToast: false,
    isErrorToast: null,
    toastText: null,
  },
  emailError: null,
  emailConfig: {},
  emailTriggers: [],
  emailTriggerDetail: null,
  emailDomain: null,
  emailDomainRecords: [],
  emailDomainVerificationSaved: null,
  emailSenderSaved: null,
  emailSenderDeleted: null,
  emailTriggerCreated: null,
  emailTriggerMeta: {},
  emailTriggerDetailConfig: {},
  emailTriggerDetailMeta: {},
  emailTriggerDetailSaved: null,
  emailTriggerDetailDeleted: null,
  emailTemplatePreview: null,
  dashboardTasks: null,
  dashboardToaster: {
    showToast: false,
    isErrorToast: null,
    toastText: null,
  },
  paypalSyncTrackingConfig: null,
  paypalButtonConfiguration: null,
  paypalDisputeResolutionConfig: null,
  paypalMetaTrackingNumberType: null,
  paypalMetaUpdateMode: null,
  paypalAccountsUpdated: null,
  paypalTrackingsData: null,
  paypalTrackingsMeta: null,
  paypalTrackingsSummary: null,
  paypalTrackingsExportMessage: null,
  paypalToaster: {
    showToast: false,
    isErrorToast: null,
    toastText: null,
  },
  unsavedModalOpen: false,
  unsavedModalFns: null,
  shipBobLoading: false,
  shipBobAccounts: null,
  shipBobInstallLink: null,
  shipbobToaster: {
    showToast: false,
    isErrorToast: null,
    toastText: null,
  },
  settingsToaster: {
    showToast: false,
    isErrorToast: null,
    toastText: null,
  },
  featureRequest: false,
  orderStatusConfigurations: null,
  orderStatusMeta: null,
  orderStatusPagePreviewLink: null,
  loadingOrderPagePreviewLink: false,
};
