import { connect } from 'react-redux';
import Stores from './Stores';
import { shopActions } from 'redux/store/shopSlice';

function mapStateToProps() {
  return {};
}

const mapDispatchToProps = {
  setStoreUUID: shopActions.setStoreUUID,
};

export default connect(mapStateToProps, mapDispatchToProps)(Stores);
