import { configureStore } from '@reduxjs/toolkit';

import rootReducer from './rootReducer';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from './rootSaga';
import { storeApi } from './storeApi';
import { baseApi } from './baseApi';
import { rtkMiddleware } from './rtkMiddleware';

const middlewares = [];
const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      middlewares,
      storeApi.middleware,
      baseApi.middleware,
      rtkMiddleware
    ),
});
sagaMiddleware.run(rootSaga);

export default store;
