import { connect } from 'react-redux';
import OmnisendDetails from './OmnisendDetails';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    loading: state.settings.loading,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OmnisendDetails);
